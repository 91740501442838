//import { loadStripe } from '@stripe/stripe-js';
//import { auth } from './firebase';

//const proPlanPriceId = 'price_1OtAtyJcTMg4yEiliPyYtJgf';
//const beyondProPlanPriceId = 'price_1OtEfVJcTMg4yEil4MbigM91';
import React, { useState } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
//import {  getStorage } from 'firebase/storage';
//import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//const storage = getStorage();
 import { loadStripe } from '@stripe/stripe-js';
//  import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import { doc, updateDoc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';

import { firestore } from './firebase'; // Use 'firestore' instead of 'db'
 import logo from './logo.png'; // Assuming you have star2.png in the same folder



function UpgradePopup({ show, onClose, subscriptionPlan }) {
  const [inviteCode, setInviteCode] = useState('');
  const [codeValidity, setCodeValidity] = useState('');

   const stripePromise = loadStripe('pk_live_51OsyIpJcTMg4yEiljrwB32KGtP0ZWTkL6621dIxGZCH6OsSDPQDDyd8Bfj642oGBZ8fpjA9zuJJDOVf8vAEwkvjp00qzk4VySK');


  // const handleUpgrade = async (priceId) => {
  //   const promoCode = inviteCode.toLowerCase();
  //   const stripe = await stripePromise;
  //   const userId = auth.currentUser?.uid;
  
  //   if (!userId) {
  //     alert('No user signed in.');
  //     return;
  //   }
  
  //   // Fetch user data from Firestore
  //   const userRef = doc(firestore, `users/${userId}`);
  //   const userDoc = await getDoc(userRef);
  
  //   if (!userDoc.exists()) {
  //     alert('User data not found.');
  //     return;
  //   }
  
  //   const userData = userDoc.data();
  //   const name = userData.name;
  //   const email = userData.email;
  
  //   // Setup other variables
  //   let trialPeriodDays = 0;
  //   let stripeAccountId = null;
  //   let applicationFeePercent = 0;
  
  //   if (promoCode === 'fun') {
  //     trialPeriodDays = 5;
  //   } else if (inviteLinks[promoCode]) {
  //     // Redirect to the associated unlimited link for the promo code
  //     window.location.href = inviteLinks[promoCode].unlimited;
  //     return;
  //   }
  
  //   const requestData = {
  //     priceId,
  //     userId,
  //     trialPeriodDays,
  //     stripeAccountId,
  //     applicationFeePercent,
  //     name,
  //     email,
  //     origin: window.location.origin,
  //   };
  
  //   try {
  //     const response = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/create-checkout-session', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestData),
  //     });
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(`Error creating checkout session: ${errorData.error}`);
  //     }
  
  //     const { id: sessionId } = await response.json();
  //     const { error } = await stripe.redirectToCheckout({ sessionId });
  
  //     if (error) {
  //       console.error('Error redirecting to Stripe Checkout:', error.message);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // };
  const handleUpgrade = async (priceId) => {
    const promoCode = inviteCode.toLowerCase();
    const stripe = await stripePromise;
    const userId = auth.currentUser?.uid;
  
    console.log('Starting upgrade process with promo code:', promoCode);
  
    if (!userId) {
      console.log('No user signed in');
      alert('No user signed in.');
      return;
    }
  
    // Fetch user data from Firestore
    const userRef = doc(firestore, `users/${userId}`);
    const userDoc = await getDoc(userRef);
  
    if (!userDoc.exists()) {
      console.log('User data not found');
      alert('User data not found.');
      return;
    }
  
    const userData = userDoc.data();
    const name = userData.name;
    const email = userData.email;
  
    console.log('User data:', { name, email });
  
    let trialPeriodDays = 0;
    let stripeAccountId = null;
  
    if (inviteLinks[promoCode]) {
      trialPeriodDays = 5; // Set trial period to 5 days for all valid codes
      if (inviteLinks[promoCode].stripeAccountId) {
        stripeAccountId = inviteLinks[promoCode].stripeAccountId;
        console.log('Stripe Connect account ID found:', stripeAccountId);
      }
      console.log(`Applied 5-day trial for promo code "${promoCode}"`);
    } else {
      console.log('No special conditions for promo code:', promoCode);
    }
  
    const requestData = {
      priceId,
      userId,
      trialPeriodDays,
      name,
      email,
      origin: window.location.origin,
    };
  
    if (stripeAccountId) {
      requestData.stripeAccountId = stripeAccountId;
    }
  
    console.log('Request data being sent to backend:', requestData);
  
    try {
      console.log('Sending request to backend...');
      const response = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/create-checkout-sessiontest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      console.log('Received response from backend');
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from backend:', errorData);
        throw new Error(`Error creating checkout session: ${errorData.error}`);
      }
  
      const { id: sessionId } = await response.json();
      console.log('Received session ID:', sessionId);
  
      console.log('Redirecting to Stripe Checkout...');
      const { error } = await stripe.redirectToCheckout({ sessionId });
  
      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error.message);
      }
    } catch (error) {
      console.error('Error in upgrade process:', error.message);
    }
  };
  
  // Setup an object to hold the Stripe links for each invite code
  const inviteLinks = {
       //this is alejandro sanchez's link
      //  super: {
      //   pro: 'https://buy.stripe.com/6oEcPUdeg3OkbRe28f',
      //   unlimited: 'https://buy.stripe.com/cN217c2zCbgM5sQcQu',
      //   beyondunlimited: 'https://buy.stripe.com/14k7vA5LOckQ3kI5l7', 
      //   stripeAccountId: 'acct_1Pie8oH2oczgCERX'
      // },
    // //this is sandria's link
    // super: {
    //   pro: 'https://buy.stripe.com/6oEcPUdeg3OkbRe28f',
    //   unlimited: 'https://buy.stripe.com/14k8zE6PS84A9J628e',
    //   beyondunlimited: 'https://buy.stripe.com/14k7vA5LOckQ3kI5l7', 
    //   stripeAccountId: 'acct_1Ov27iI9Fm2mY3G5'
    // },
    // Add more invite codes and their associated links here
    gift: {
      // this is williams link
      pro: 'https://buy.stripe.com/6oE9DI0ru98EbRe28d',
      unlimited: 'https://buy.stripe.com/cN23fkdeg4Sof3q002',
      //stripeAccountId: 'acct_1Ov27iI9Fm2mY3G5'
    },
    fun: {
    // this is adam's link
      pro: 'https://buy.stripe.com/3cs4jo2zC3Ok6wU003',
      unlimited: 'https://buy.stripe.com/cN2g26gqs0C8dZm3cg',
      stripeAccountId: null
    },
    chase: {
      // this is chase's link
        pro: 'https://buy.stripe.com/9AQ6rwcac4SodZm9AJ',
        unlimited: 'https://buy.stripe.com/00g7vAgqsfx2f3q5ks',
        stripeAccountId: 'acct_1Ovlh8B1cGgx91yu'
      },
      leah: {
        // this is leahs's link
          pro: 'https://buy.stripe.com/28odTYdegbgM3kI8wH',
          unlimited: 'https://buy.stripe.com/00g8zEgqs84AcVi14e',
          stripeAccountId: null

        },
        easya: {
          // this is Julia UBertaccio's link
            pro: 'https://buy.stripe.com/7sIeY2b682KgaNafZb',
            unlimited: 'https://buy.stripe.com/eVa9DI7TW0C8aNa14g'
          },
          smartie: {
            // this is Masha's link
              pro: 'https://buy.stripe.com/5kA8zE2zCgB608w7sH',
              unlimited: 'https://buy.stripe.com/bIYg263DGgB68F2bIW'
            },
            // freetrial: {
            //   // this is Yuan Haoxuan's link
            //     pro: 'https://buy.stripe.com/bIYdTY4HK70w8F23ct',
            //     unlimited: 'https://buy.stripe.com/7sI9DI2zC84AdZmdR6'
            //   },
              100: {
                // this is Carolanne's link
                  pro: 'https://buy.stripe.com/00g4jodeg98Eg7ucN5',
                  unlimited: 'https://buy.stripe.com/4gwdTYdegacIf3q6oG'
                },
                studydeal: {
                  // this is John Shield's link
                    pro: 'https://buy.stripe.com/6oE9DI7TWdoUg7u28t',
                    unlimited: 'https://buy.stripe.com/aEU17c4HK4SobRe3cw',
                    stripeAccountId: 'acct_1OwpxNKOOGXpKya0'

                  },
                  learnlaugh: {
                    // this is Nico Pigazzi's link
                      pro: 'https://buy.stripe.com/fZe2bgeikgB65sQeVh',
                      unlimited: 'https://buy.stripe.com/dR60380ru2Kg08w3cy'
                    },
                    bigbrain: {
                      // this is Tayeb's link
                        pro: 'https://buy.stripe.com/7sIdTYgqs98E1cA28x',
                        unlimited: 'https://buy.stripe.com/7sIcPU4HK5WsbRedRe'
                      },
                      learnfun: {
                        // this is Julianne's link
                          pro: 'https://buy.stripe.com/7sI9DIa24acIf3qdRh',
                          unlimited: 'https://buy.stripe.com/8wM4jocacdoU3kI9B0',
                          stripeAccountId: 'acct_1Oxa9DLVFe92zI9j'
                        },
                        gradeup: {
                          // this is Rupesh's link
                            pro: 'https://buy.stripe.com/00gg268Y0fx25sQeVn',
                            unlimited: 'https://buy.stripe.com/bIY9DIcacbgMbRe4gI',
                            stripeAccountId: 'acct_1OyNkVE21c7QAJyk'
                          },
                          // boost: {
                          //   // this is Chris's link
                          //     pro: 'https://buy.stripe.com/6oE17ceik84A5sQ9B5',
                          //     unlimited: 'https://buy.stripe.com/dR6eY21vy4SobRe00u',
                          //     stripeAccountId: 'acct_1OybOFLzYrkHghDg'

                          //   },
                            gptscholar: {
                              // this is Ethan Zhao's link
                                pro: 'https://buy.stripe.com/dR6eY21vyesYf3qeVr',
                                unlimited: 'https://buy.stripe.com/4gwg26b683Ok5sQ9B6',
                                // stripeAccountId: 'acct_1OybOFLzYrkHghDg'
                              },
                              jamie: {
                                // this is Jamie Drappi's link
                                  pro: 'https://buy.stripe.com/00gcPU1vy84A8F2eVt',
                                  unlimited: 'https://buy.stripe.com/5kAg265LO1Gcf3qfZw',
                                  //stripeAccountId: 'acct_1OybOFLzYrkHghDg'
                                },
                                leoyang: {
                                  // this is leoyang Jingchao's link
                                    pro: 'https://buy.stripe.com/fZe6rw8Y00C8dZmfZz',
                                    unlimited: 'https://buy.stripe.com/9AQcPUcac2KgaNa7t2'
                                  },
                                  superjules: {
                                    // this is Julia Citron's link
                                      pro: 'https://buy.stripe.com/cN2038eik3Ok4oM6p1',
                                      unlimited: 'https://buy.stripe.com/7sI6rwb6884A6wU6p0'
                                    },
                                    mirren: {
                                      // this is mirren's link
                                        pro: 'https://buy.stripe.com/6oE8zE8Y0doU6wUcNr',
                                        unlimited: 'https://buy.stripe.com/28o038eik0C8cVifZC'
                                      },
                                      lexi: {
                                        // this is alexa counseli's link
                                          pro: 'https://buy.stripe.com/aEUbLQ2zCdoUbRe8xd',
                                          unlimited: 'https://buy.stripe.com/cN2eY22zCbgM2gE7t8'
                                        },
                                        adrienne: {
                                          // this is Adrienne's link
                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                            unlimited: 'https://buy.stripe.com/bIYaHM7TWdoU5sQdRy',
                                            stripeAccountId: 'acct_1P2JWbBAxoyVj0og'
                                          },
                                          ashley: {
                                            // this is ashley davidsons link
                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                              unlimited: 'https://buy.stripe.com/28o17c5LObgM4oM9Bl',
                                              stripeAccountId: 'acct_1P2cM9ErtC5aqUzo'
                                            },
                                            profloboz: {
                                              // this is Jason Pantoja s link
                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                unlimited: 'https://buy.stripe.com/7sIbLQ2zC98E3kIaFs',
                                                beyondunlimited: 'https://buy.stripe.com/4gw2bg7TW5Ws2gE8xl', 
                                              },
                                              gabi: {
                                                // this is gabriella russo's link
                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                  unlimited: 'https://buy.stripe.com/5kA4jo3DG4So6wU00Q',
                                                  beyondunlimited: 'https://buy.stripe.com/fZeeY22zC5Ws5sQdRH', 
                                                  stripeAccountId: 'acct_1P4DMVKnIpTdl84U'

                                                },
                                                salvatore: {
                                                  // this is salvatore penecale link
                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                    unlimited: 'https://buy.stripe.com/dR6dTYgqs70wcVi9Bs',
                                                    beyondunlimited: 'https://buy.stripe.com/eVag262zCacIg7u8xp', 
                                                  },
                                                  bcstudent: {
                                                    // this is Camelia Modesto Ramayo's link
                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                      unlimited: 'https://buy.stripe.com/fZe9DI2zC0C8aNa4ha',
                                                      beyondunlimited: 'https://buy.stripe.com/9AQ8zEfmo84AaNaeVP', 
                                                    },
                                  
                                                      // mike: {
                                                      //   // this is mike smith's link
                                                      //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                      //     unlimited: 'https://buy.stripe.com/3cs3fk4HK98EaNa00Y',
                                                      //     beyondunlimited: 'https://buy.stripe.com/7sIdTYgqsbgM5sQbJH', 
                                                      //     stripeAccountId: 'acct_1P4DMVKnIpTdl84U'

                                                      //   },
                                                        vishal: {
                                                          // this is vishal's link
                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                            unlimited: 'https://buy.stripe.com/dR68zEdeg84AcVi7ts',
                                                            beyondunlimited: 'https://buy.stripe.com/fZecPU8Y098E7AY8xx', 
                                                            stripeAccountId: 'acct_1P625HKrbzngtATa'

                                                          },
                                                          lauryn: {
                                                            // this is lauryn williams's link
                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                              unlimited: 'https://buy.stripe.com/00g17ccac84AcVi3de',
                                                              beyondunlimited: 'https://buy.stripe.com/4gwaHMeikbgM8F24hj', 
                                                              stripeAccountId: 'acct_1P3kZOCmkkxrVIEO'

                                                            },
                                                            riya: {
                                                              // this is riya thakkar's link
                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                unlimited: 'https://buy.stripe.com/7sIcPU7TW98E08w3dg',
                                                                beyondunlimited: 'https://buy.stripe.com/28o0382zCdoU7AY3dh', 
                                                                stripeAccountId: 'acct_1P6QrzK5ESDzrrJD'
                                                              },
                                                              jules: {
                                                                // this is juliana defino's link
                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                  unlimited: 'https://buy.stripe.com/7sI2bg2zC84AdZm9BG',
                                                                  beyondunlimited: 'https://buy.stripe.com/3cs6rwa241Gcg7u4hn', 
                                                                  stripeAccountId: 'acct_1P6d7RCvs8Bqj8HK'

                                                                },
                                                                jason: {
                                                                  // this is  jason giersch link
                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                    unlimited: 'https://buy.stripe.com/bIYdTY2zC84A3kIg06',
                                                                    beyondunlimited: 'https://buy.stripe.com/eVa9DI3DGfx2f3qcNV', 
                                                                    stripeAccountId: 'acct_1P7jjhAHk3cYOOLM'

                                                                  },
                                                                  edu: {
                                                                    // this is Ibrahim Aweniya link
                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                      unlimited: 'https://buy.stripe.com/cN25ns8Y02Kgg7ubJS',
                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                      stripeAccountId: 'acct_1P7kekIuYvzEwpBD'

                                                                    },
                                                                    supertutor24: {
                                                                      // this is Jonathan Preval's link
                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                        unlimited: 'https://buy.stripe.com/14k7vA0ru4So1cAcNY',
                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                        stripeAccountId: 'acct_1P8ADTEcIgG29uEu'

                                                                      },
                                                                      // andy: {
                                                                      //   // this is Andrew Tippett's link
                                                                      //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                      //     unlimited: 'https://buy.stripe.com/3csaHMgqs84A5sQdS3',
                                                                      //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                      //     stripeAccountId: 'acct_1P8A89FEIQjZjMb4'

                                                                      //   },
                                                                        yay: {
                                                                          // this is Elizabeth Suffian's link
                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                            unlimited: 'https://buy.stripe.com/4gwaHM7TW0C88F2bJW',
                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                          },
                                                                          sahi: {
                                                                            // this is Sahijpal Ghotra link
                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                              unlimited: 'https://buy.stripe.com/eVa0387TW1Gc1cA9BP',
                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                            },
                                                                            ian: {
                                                                              // this is Ian Donohue link
                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                unlimited: 'https://buy.stripe.com/7sI8zEcac2Kg2gE7tI',
                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                              },
                                                                              vince: {
                                                                                // this is vincent duyungan's link
                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                  unlimited: 'https://buy.stripe.com/4gwg267TWacIbRe01h',
                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                },
                                                                                sean: {
                                                                                  // this is sean sear's link
                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                    unlimited: 'https://buy.stripe.com/9AQ2bg6PS3Okf3qeWc',
                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                  },
                                                                                  linh: {
                                                                                    // this is linh tran's link
                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                      unlimited: 'https://buy.stripe.com/3cs6rw0ruesY8F25lD',
                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn',
                                                                                      stripeAccountId: 'acct_1P7ivcGSLU7qxxKt'
 
                                                                                    },
                                                                                    etutor: {
                                                                                      // this is emaad tareen's link
                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                        unlimited: 'https://buy.stripe.com/14k7vA2zC5Ws2gE29s',
                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                      },
                                                                                        neev: {
                                                                                          // this is neev chopra's link
                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                            unlimited: 'https://buy.stripe.com/4gweY21vydoUbRe9BW',
                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                          },
                                                                                          blazer: {
                                                                                            // this is Daniel Obebshalom's link
                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                              unlimited: 'https://buy.stripe.com/6oEdTY7TWgB68F23dz',
                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                            },
                                                                                            youreright: {
                                                                                              // this is Jia Mei Lin's link
                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                unlimited: 'https://buy.stripe.com/28o6rw7TW5Ws5sQbK6',
                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                              },
                                                                                              aiden: {
                                                                                                // this is aiden gajewski's link
                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                  unlimited: 'https://buy.stripe.com/3cs4jo2zCdoU9J6dSf',
                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                  stripeAccountId: 'acct_1P9UEfDKDU81UpM5',                                                                                                                                                                                      
                                                                                                },
                                                                                                casey: {
                                                                                                  // this is casey woods's link
                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                    unlimited: 'https://buy.stripe.com/28o6rw7TW5Ws5sQbK6',
                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                  },
                                                                                                  chan: {
                                                                                                    // this is Ashley Chan's link
                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                      unlimited: 'https://buy.stripe.com/28oaHMgqsckQ5sQ29z',
                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                    },
                                                                                                    kayleen: {
                                                                                                      // this is kayleen issabel chan 's link
                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                        unlimited: 'https://buy.stripe.com/6oEeY2degdoU8F2eWk',
                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                      },
                                                                                               
                                                                                                      nikita: {
                                                                                                        // this is nikita dutt's link
                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                          unlimited: 'https://buy.stripe.com/bIYeY26PSfx24oM6pR',
                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                        },
                                                                                                        freetrial: {
                                                                                                          // this is Woo Sik Choi's link
                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                            unlimited: 'https://buy.stripe.com/8wM3fk8Y03OkdZm7tW',
                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                            stripeAccountId: 'acct_1PAEpGIp9wh49B5K'                                                                                                                                                                                     

                                                                                                          },
                                                                                                          winky: {
                                                                                                            // this is Ian Sherry's link
                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                              unlimited: 'https://buy.stripe.com/6oE8zEeik3Okf3q3dH',
                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                            },
                                                                                                            victor: {
                                                                                                              // this is Victor Rubio's link
                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                unlimited: 'https://buy.stripe.com/00gaHMdeg70waNabKe',
                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                              },
                                                                                                              serenity: {
                                                                                                                // this is Tania Ramirez's link
                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                  unlimited: 'https://buy.stripe.com/bIY5nsfmodoU08w01y',
                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                },
                                                                                                                nick: {
                                                                                                                  // this is nick hick's link
                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                    unlimited: 'https://buy.stripe.com/28o9DI3DGfx27AY29H',
                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                  },
                                                                                                                  pizza: {
                                                                                                                    // this is elettra speciale's link
                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                      unlimited: 'https://buy.stripe.com/cN22bg2zC2KgcVi8y6',
                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                      stripeAccountId: 'acct_1PBKWaQxGZawVjWf'                                                                                                                                                                                        

                                                                                                                    },
                                                                                                                    fsu: {
                                                                                                                      // this is connor rogan's link
                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                        unlimited: 'https://buy.stripe.com/9AQbLQ7TW5WsaNa9Cb',
                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                      },
                                                                                                                 
                                                                                                                        cassandra: {
                                                                                                                          // this is cassandra maurice's link
                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                            unlimited: 'https://buy.stripe.com/3cs8zEgqs3Ok7AY8y9',
                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                            stripeAccountId: 'acct_1PC8f2A3u5AGywnx'                                                                                                                                                                                       

                                                                                                                          },
                                                                                                                          // learn: {
                                                                                                                          //   // this is Bridgette Rupp's link
                                                                                                                          //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                          //     unlimited: 'https://buy.stripe.com/00g17cfmo4So8F2g0C',
                                                                                                                          //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                          //   },
                                                                                                                            intellectnow: {
                                                                                                                              // this is Anishika Chhabra's link
                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                unlimited: 'https://buy.stripe.com/fZe9DIgqsdoUaNaaGj',
                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                              },
                                                                                                                              ready: {
                                                                                                                                // this is Daniel Dayisi's link
                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                  unlimited: 'https://buy.stripe.com/cN20380ru5Wsf3q3dS',
                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                  stripeAccountId: 'acct_1PBUZDJD2pI95k6u'                                                                                                                                                                                        

                                                                                                                                },
                                                                                                                                cielo: {
                                                                                                                                  // this is cielo rosas ocampo's link
                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                    unlimited: 'https://buy.stripe.com/bIY7vAa24fx23kIeWB',
                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                  },
                                                                                                                                  maeve: {
                                                                                                                                    // this is maeve fishel's link
                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                      unlimited: 'https://buy.stripe.com/7sI9DI3DGacI3kIg0G',
                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                    },
                                                                                                                                    liz: {
                                                                                                                                      // this is Elizabeth Wilburn's link
                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM7vAb68ckQ9J66q7',
                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                      },
                                                                                                                                      // joy: {
                                                                                                                                      //   // this is vanessa ulrich's link
                                                                                                                                      //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                      //     unlimited: 'https://buy.stripe.com/6oEdTYb681Gc5sQ01K',
                                                                                                                                      //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                      //   },
                                                                                                                                        abbyshao: {
                                                                                                                                          // this is abby shao's link
                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                            unlimited: 'https://buy.stripe.com/eVacPU7TW5WsdZm3dX',
                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                          },
                                                                                                                                          navpreet: {
                                                                                                                                            // this is navpreet sangha's link
                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                              unlimited: 'https://buy.stripe.com/6oE17cb68esY6wUdSC',
                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                            },
                                                                                                                                            brandon: {
                                                                                                                                              // this is brandon scott's link
                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                unlimited: 'https://buy.stripe.com/28o6rw1vy70w9J67uf',
                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                              },
                                                                                                                                              brooke: {
                                                                                                                                                // this is brooke McCloskey's link
                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                  unlimited: 'https://buy.stripe.com/aEUbLQ1vy84A5sQ3e0',
                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                },
                                                                                                                                                nayla: {
                                                                                                                                                  // this is nayla ariwibowo's link
                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                    unlimited: 'https://buy.stripe.com/14kcPU7TW70waNa5m9',
                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                  },
                                                                                                                                                  dairille: {
                                                                                                                                                    // this is dairille basilio's link
                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                      unlimited: 'https://buy.stripe.com/4gwaHM3DGdoU9J6aGu',
                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                      stripeAccountId: 'acct_1PF2RcBNI1k1BfKW'                                                                                                                                                                                        

                                                                                                                                                    },
                                                                                                                                                    kristie: {
                                                                                                                                                      // this is kristie kijek's link
                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                        unlimited: 'https://buy.stripe.com/28o4jo0ru2Kgg7u15V',
                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                        stripeAccountId: 'acct_1PF2RcBNI1k1BfKW'                                                                                                                                                                                        

                                                                                                                                                      },
                                                                                                                                                      kaitlyn: {
                                                                                                                                                        // this is kaitlyn zhang's link
                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                        },
                                                                                                                                                        // study: {
                                                                                                                                                        //   // this is taran konda's link
                                                                                                                                                        //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                        //     unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                        //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                        //     stripeAccountId: 'acct_1PF09FDe7QEY4yW0'                                                                                                                                                                                        
                                                                                                                                                        //   },
                                                                                                                                                          ethan: {
                                                                                                                                                            // this is ethan ong's link
                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                              unlimited: 'https://buy.stripe.com/14k17cdegacI1cAbKC',
                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                              stripeAccountId: 'acct_1PF3ijBkqGjwj7pb'                                                                                                                                                                                        

                                                                                                                                                            },
                                                                                                                                                            andrew: {
                                                                                                                                                              // this is andrew white's link
                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                unlimited: 'https://buy.stripe.com/00g4jo3DGfx2g7ucOH',
                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                stripeAccountId: 'acct_1PEZBpJARnlYvNL3'                                                                                                                                                                                       

                                                                                                                                                              },
                                                                                                                                                              clay: {
                                                                                                                                                                // this is clay cortright's link
                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                  unlimited: 'https://buy.stripe.com/4gwbLQb68gB69J6eWQ',
                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                },
                                                                                                                                                                cat: {
                                                                                                                                                                  // this is cat clark's link
                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4joeik3Okg7u6ql',
                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                    stripeAccountId: 'acct_1PGDMCDA9r1v7oYA'                                                                                                                                                                                       

                                                                                                                                                                  },
                                                                                                                                                                  sylvia: {
                                                                                                                                                                    // this is sylvia owino's link
                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                      unlimited: 'https://buy.stripe.com/dR6bLQ4HK84AcVi01Y',
                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                      stripeAccountId: 'acct_1PAi1qEX4j2AqkHj'                                                                                                                                                                                        

                                                                                                                                                                    },
                                                                                                                                                                    // tutor: {
                                                                                                                                                                    //   // this is brennan hipkiss's link
                                                                                                                                                                    //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                    //     unlimited: 'https://buy.stripe.com/28odTY8Y070w7AY9Cz',
                                                                                                                                                                    //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                    //   },
                                                                                                                                                                      baylor: {
                                                                                                                                                                        // this is baylor shelton's link
                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o5ns5LO3Ok8F2020',
                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                          stripeAccountId: 'acct_1PAh9oAQx1xh3jfY'                                                                                                                                                                                        

                                                                                                                                                                        },
                                                                                                                                                                        jillian: {
                                                                                                                                                                          // this is jillian walter's link
                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                            unlimited: 'https://buy.stripe.com/3csaHM0ruacI5sQ8yx',
                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                          },
                                                                                                                                                                          amari: {
                                                                                                                                                                            // this is amari lindo's link
                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                              unlimited: 'https://buy.stripe.com/dR6g26b6884A9J6dSS',
                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                            },
                                                                                                                                                                            surie: {
                                                                                                                                                                              // this is suriatu lamin's link
                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                unlimited: 'https://buy.stripe.com/aEUdTY7TW84AdZm4ik',
                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                              },
                                                                                                                                                                              colton: {
                                                                                                                                                                                // this is colton duke's link
                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/14k8zEcac3OkdZmeWZ',
                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                },
                                                                                                                                                                                jcwei: {
                                                                                                                                                                                  // this is justin chua's link
                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/4gw17cgqs98EdZm8yC',
                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn',
                                                                                                                                                                                    stripeAccountId: 'acct_1PAh9oAQx1xh3jfY'                                                                                                                                                                                        

                                                                                                                                                                                  },
                                                                                                                                                                                  lucas: {
                                                                                                                                                                                    // this is lucas voss's link
                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/eVa6rw3DGesY5sQ027',
                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                      stripeAccountId: 'acct_1PGpLvB5aLy0hW9h'                                                                                                                                                                                        

                                                                                                                                                                                    },
                                                                                                                                                                                    aryanna: {
                                                                                                                                                                                      // this is aryanna wright's link
                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/fZecPU7TWbgM5sQ8yE',
                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                        stripeAccountId: 'acct_1PGmhvJw1aKiE0ig'                                                                                                                                                                                        

                                                                                                                                                                                      },
                                                                                                                                                                                      five: {
                                                                                                                                                                                        // this is avari eismon's link
                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/eVag26b68fx2aNa2ah',
                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                          stripeAccountId: 'acct_1PGpduFZtCdco9cE'                                                                                                                                                                                        

                                                                                                                                                                                        },
                                                                                                                                                                                        gabe: {
                                                                                                                                                                                          // this is gabe olson's link
                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/14k7vA0ruesY4oM02a',
                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                          },
                                                                                                                                                                                          libby: {
                                                                                                                                                                                            // this is elizabeth mason's link
                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/3csdTY5LO70wg7ueX5',
                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                              stripeAccountId: 'acct_1PH7RVAdRKOopzUc'                                                                                                                                                                                       

                                                                                                                                                                                            },
                                                                                                                                                                                            aiedu: {
                                                                                                                                                                                              // this is jared jackson's link
                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/9AQg26a240C88F2g1a',
                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                              },
                                                                                                                                                                                              elizabeth: {
                                                                                                                                                                                                // this is Elizabeth Reucassel's link
                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o6rwgqs98E6wUcOZ',
                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                },
                                                                                                                                                                                                sidekick: {
                                                                                                                                                                                                  // this is David Stacy's link
                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/fZedTYeik84A4oM6qC',
                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                    stripeAccountId: 'acct_1PGRM2K1wuDI3UKM'                                                                                                                                                                                 

                                                                                                                                                                                                  },
                                                                                                                                                                                                  nika: {
                                                                                                                                                                                                    // this is nicolle nizberg's link
                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/14kaHM5LO5Ws6wU9CP',
                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                      stripeAccountId: 'acct_1PH9YnDLKxANjjcM'                                                                                                                                                                                        

                                                                                                                                                                                                    },
                                                                                                                                                                                                    hero: {
                                                                                                                                                                                                      // this is samuel nason's link
                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/cN20385LO0C86wUbKY',
                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                        stripeAccountId: 'acct_1PIWXuJu6mQBInBZ'                                                                                                                                                                                        

                                                                                                                                                                                                      },
                                                                                                                                                                                                      nabil: {
                                                                                                                                                                                                        // this is nabil chowdury's link
                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/4gwg261vydoUf3q8yN',
                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                          stripeAccountId: 'acct_1PIXjIFVkMGv8NmJ'                                                                                                                                                                                        

                                                                                                                                                                                                        },
                                                                                                                                                                                                          education: {
                                                                                                                                                                                                            // this is stefanny alice's link
                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/3cs3fkfmoacIf3qcP5',
                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                            },
                                                                                                                                                                                                            myers: {
                                                                                                                                                                                                              // this is jacqueline myer's link
                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/3csdTY5LObgM08w2aq',
                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                stripeAccountId: 'acct_1P99jhBMaQs8arFO'                                                                                                                                                                                        

                                                                                                                                                                                                              },
                                                                                                                                                                                                              // happy: {
                                                                                                                                                                                                              //   // this is ngoc tam nhu nguyen 's link
                                                                                                                                                                                                              //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                              //     unlimited: 'https://buy.stripe.com/dR62bga24ckQdZm6qI',
                                                                                                                                                                                                              //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                              //     stripeAccountId: 'acct_1PIcPMGO3Hc4wB2S'                                                                                                                                                                                       

                                                                                                                                                                                                              //   },
                                                                                                                                                                                                                teach: {
                                                                                                                                                                                                                  // this is lauren pollock's link
                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/00g6rwdegfx2dZmcP7',
                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn',
                                                                                                                                                                                                                    stripeAccountId: 'acct_1PJ0QTDOlNl0aoPh'                                                                                                                                                                                       
 
                                                                                                                                                                                                                    
                                                                                                                                                                                                                  },
                                                                                                                                                                                                                  archimedes: {
                                                                                                                                                                                                                    // this is amit senauth's link
                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/fZe7vA0rufx24oM02m',
                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                    },
                                                                                                                                                                                                                 
                                                                                                                                                                                                                      yushika: {
                                                                                                                                                                                                                        // this is yushika uprety's link
                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/aEUaHM6PS0C808waH2',
                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                        },
                                                                                                                                                                                                                        read: {
                                                                                                                                                                                                                          // this is lucas read's link
                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/aEUaHM2zCckQ8F25mJ',
                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                          },
                                                                                                                                                                                                                          orange: {
                                                                                                                                                                                                                            // this is nancy liu's link
                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM7vA5LO4So8F202q',
                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                            },
                                                                                                                                                                                                                            erin: {
                                                                                                                                                                                                                              // this is erin ta's link
                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/00gcPU8Y098E6wU6qP',
                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                              },
                                                                                                                                                                                                                              yourtutor: {
                                                                                                                                                                                                                                // this is Sharmila Dahal's link
                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/bIYdTYfmockQ1cA9D2',
                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PK2gxLStA21Qbqc'                                                                                                                                                                                       

                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                mary: {
                                                                                                                                                                                                                                  // this is mary kate krueger's link
                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/dR6cPU1vygB6f3qdTj',
                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PLI85KqWw3MdEbS'                                                                                                                                                                                    

                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                  sydney: {
                                                                                                                                                                                                                                    // this is sydney robert's link
                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/eVag262zC70waNaaH8',
                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                    tau: {
                                                                                                                                                                                                                                      // this is Felix chase's friend 's link
                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/14kg26deg84A08wcPh',
                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                      kate: {
                                                                                                                                                                                                                                        // this is kate anders link
                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/cN2g26b68ckQ5sQcPj',
                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PMYtXGWO2zChHgW'                                                                                                                                                                                    

                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                        tien: {
                                                                                                                                                                                                                                          // this is ethan tien's friend 's link
                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/00g5nsa24esYf3q7uY',
                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PMNYrBlWCssGpOh'                                                                                                                                                                                    

                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                          carol: {
                                                                                                                                                                                                                                            // this is Caroline Williams's link
                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/cN2cPU5LOesYf3qg1w',
                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn',
                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PMuaVKX2eSA35di'                                                                                                                                                                                    
 
                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                            josh: {
                                                                                                                                                                                                                                              // this is josh young's link
                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o0387TW1GcbRe2aH',
                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PMwWsIptjAlTITq'                                                                                                                                                                                    

                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                              prekya: {
                                                                                                                                                                                                                                                // this is prekya thiru's link
                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/dR6eY25LO4So3kI6qZ',
                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PNGMAQwYwVKehaK'                                                                                                                                                                                        

                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                bryanna: {
                                                                                                                                                                                                                                                  // this is bryanna mcomish thiru's link
                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/4gw5nsb684SocVi8z6',
                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PNIBdHAO6tqRyIj'                                                                                                                                                                                  

                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                  christian: {
                                                                                                                                                                                                                                                    // this is christian prokopeas 's link
                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/4gw8zEeik3Ok4oM7v4',
                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                    wsb: {
                                                                                                                                                                                                                                                      // this is wesley burgess 's link
                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/7sI4jo8Y0gB67AY8za',
                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                      bryce: {
                                                                                                                                                                                                                                                        // this is bryce lucas 's link
                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/cN2cPU3DG5Ws6wU7v7',
                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                        // mia: {
                                                                                                                                                                                                                                                        //   // this is meijia wu 's link
                                                                                                                                                                                                                                                        //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                        //     unlimited: 'https://buy.stripe.com/00g5nsa24esYbRe2aL',
                                                                                                                                                                                                                                                        //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                        //     stripeAccountId: 'acct_1PMwWsIptjAlTITq'                                                                                                                                                                                    

                                                                                                                                                                                                                                                        //   },
                                                                                                                                                                                                                                                          bella: {
                                                                                                                                                                                                                                                            // this is isabella hamm 's link
                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28odTYdeg98E7AY8zd',
                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                            sheryl: {
                                                                                                                                                                                                                                                              // this is sheryl kim  's link
                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/cN22bga2498E8F2bLo',
                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PNP3rLOccCbolm7'                                                                                                                                                                                       

                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                              smart: {
                                                                                                                                                                                                                                                                // this is mickey mai's link
                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/bIY7vAb68esY4oMeXC',
                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PNbhvIXO4YMXPo1'                                                                                                                                                                                      

                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                varsha: {
                                                                                                                                                                                                                                                                  // this is varsha pai's link
                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/4gw8zE1vy3Ok2gEbLr',
                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PNiHkANNrhdYkAw'                                                                                                                                                                                      

                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                  ban: {
                                                                                                                                                                                                                                                                    // this is ban zuerlein's link
                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/6oE6rw4HK5Ws5sQ3eW',
                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PN2nzFp9qAz356l'                                                                                                                                                                             
                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                    aismart: {
                                                                                                                                                                                                                                                                      // this is anshuman gupta's link
                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/6oEbLQ6PSgB64oMcPy',
                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                      babia: {
                                                                                                                                                                                                                                                                        // this is babia prakash's link
                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/3cscPU0ru70wbRecPx',
                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                        // star: {
                                                                                                                                                                                                                                                                        //   // this is isabella egizio 's link
                                                                                                                                                                                                                                                                        //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                        //     unlimited: 'https://buy.stripe.com/3csdTYfmo98EdZmbLv',
                                                                                                                                                                                                                                                                        //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                        //   },
                                                                                                                                                                                                                                                                          daisy: {
                                                                                                                                                                                                                                                                            // this is daisy zhou 's link
                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/cN2dTY1vy84A2gEcPA',
                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                            matamala: {
                                                                                                                                                                                                                                                                              // this is isaiah matamala 's link
                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/00g7vA1vy2Kg08w2aX',
                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                              dakota: {
                                                                                                                                                                                                                                                                                // this is dakota roger 's link
                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/6oEg264HK5Ws3kIg1O',
                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                kenzie: {
                                                                                                                                                                                                                                                                                  // this is kenzie mullen's link
                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/eVa17c2zC84AbRedTH',
                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1POLpOLsTJEEC58f'                                                                                                                                                                             

                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                  create: {
                                                                                                                                                                                                                                                                                    // this is joy strittmatter mullen's link
                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/7sI038deggB61cAaHw',
                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                    minh: {
                                                                                                                                                                                                                                                                                      // this is  minh nguyen's link
                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/dR6dTY2zCfx2g7ueXN',
                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 

                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                      // learn: {
                                                                                                                                                                                                                                                                                      //   // this is  justin samoryk's link
                                                                                                                                                                                                                                                                                      //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                      //     unlimited: 'https://buy.stripe.com/bIY8zE4HK84AcVi3f6',
                                                                                                                                                                                                                                                                                      //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                      //     stripeAccountId: 'acct_1POhs0JFUi4Z0KxF'                                                                                                                                                                                      

                                                                                                                                                                                                                                                                                      //   },
                                                                                                                                                                                                                                                                                        shaurya: {
                                                                                                                                                                                                                                                                                          // this is  shaurya srivastava's link
                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/aEU2bg3DGesY6wU7vn',
                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1POtS0GNt51GeHHA'                                                                                                                                                                                 

                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                          jo: {
                                                                                                                                                                                                                                                                                            // this is  josephine knapik's link
                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/00gaHM6PSfx2aNa7vo',
                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PPQgPCnutfFqyCt'                                                                                                                                                                                    

                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                            mariam: {
                                                                                                                                                                                                                                                                                              // this is  mariam ghreuli's link
                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/bIYaHM5LO3OkbRebLF',
                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PPSQCGmFVIP8u3V'                                                                                                                                                                                       

                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                              withfun: {
                                                                                                                                                                                                                                                                                                // this is  livya kanwal's link
                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/3cscPU6PSacI7AY7vq',
                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1POW9iL5ycuKejJK'                                                                                                                                                                

                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                something: {
                                                                                                                                                                                                                                                                                                  // this is  cheng liu's link
                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/6oEg267TWfx208w2b7',
                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PPphdAn35ihb0Hs'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                  tutorjohn: {
                                                                                                                                                                                                                                                                                                    // this is  johnathan sertl 's link
                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/14k8zE8Y0ckQ9J6cPM',
                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PQ7EyBpQNC8SjJ1'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                    nolan: {
                                                                                                                                                                                                                                                                                                      // this nolan  o'callaghan 's link
                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/00g0387TWdoU4oM8zx',
                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PPo4pDU1KDxVNFk'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                      emerson: {
                                                                                                                                                                                                                                                                                                        // this emerson  mellon 's link
                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/fZecPU5LOacI2gEdTS',
                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PQ8bVA4uc2aKxLs'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                        jestine: {
                                                                                                                                                                                                                                                                                                          // this jestine  tovar 's link
                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/fZe4jo6PS2Kg6wU033',
                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                          tamisha: {
                                                                                                                                                                                                                                                                                                            // this tamisha  smith 's link
                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/00g3fk2zCacI3kI8zA',
                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                            futureai: {
                                                                                                                                                                                                                                                                                                              // this kevin hoang 's link
                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/aEU8zEb681GcdZm6rt',
                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                              shah: {
                                                                                                                                                                                                                                                                                                                // this siddharth shah 's link
                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/4gweY2fmoesY5sQaHK',
                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                marissa: {
                                                                                                                                                                                                                                                                                                                  // this marissa deville's link
                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28odTYb682Kgg7u4jn',
                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                  focus: {
                                                                                                                                                                                                                                                                                                                    // this Aiden Zarrinnam's link
                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/5kA038a24fx29J6038',
                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PQKasGJajtXm6qm'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                    douglas: {
                                                                                                                                                                                                                                                                                                                      // this douglas pierce's link
                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/fZeeY2b68ckQ08wdTZ',
                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                      alec: {
                                                                                                                                                                                                                                                                                                                        // this Alec Zhou's link
                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/eVa5nscac84AcVidU0',
                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PQEeXLOzXN0FVYJ'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                        cerb: {
                                                                                                                                                                                                                                                                                                                          // this catherine erb's link
                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/fZe3fk8Y0doUaNa17f',
                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                          // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                          henrik: {
                                                                                                                                                                                                                                                                                                                            // this henrik nelson's link
                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o2bg2zC70wg7uaHQ',
                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                            gauth: {
                                                                                                                                                                                                                                                                                                                              // this gautham shankar's link
                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/aEU0387TWgB64oM17h',
                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                              aj: {
                                                                                                                                                                                                                                                                                                                                // this annaliese jackson's link
                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/bIY2bg7TWgB69J65ny',
                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                patrick: {
                                                                                                                                                                                                                                                                                                                                  // this patrick gau's link
                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/dR63fkfmofx2f3q8zL',
                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                  kennedy: {
                                                                                                                                                                                                                                                                                                                                    // this kennedy parker's link
                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/fZe3fk8Y05WsbRe03h',
                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                    lauren: {
                                                                                                                                                                                                                                                                                                                                      // this lauren tauber 's link
                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/00g7vAa245WsbRedU6',
                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PRLQsEb7ieEw5Hp'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                      hannah: {
                                                                                                                                                                                                                                                                                                                                        // this hannah wei 's link
                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/fZe8zE3DG0C81cA8zO',
                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PPYcXK34WXNRpdo'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                        kobi: {
                                                                                                                                                                                                                                                                                                                                          // this kobi anderson's link
                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o0382zC5Ws08w3fv',
                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PRQvuJfj1kH7dmM'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                          question: {
                                                                                                                                                                                                                                                                                                                                            // this kevin teagle's link
                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/eVa5ns8Y00C85sQbM2',
                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PS2C8GEAB3826p0'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                            jalynn: {
                                                                                                                                                                                                                                                                                                                                              // this jalynn edwards's link
                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/3cs9DIeik4So4oMdUb',
                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PSSPUBkqAjfsPRI'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                              ellie: {
                                                                                                                                                                                                                                                                                                                                                // this elizabeth perez's link
                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o038cac1Gc5sQ7vO',
                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PSUGRBTqKE6B2jL'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                milly: {
                                                                                                                                                                                                                                                                                                                                                  // this milly wong's link
                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/14kg26eikgB6aNag2l',
                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PSVgSHWDzeudVV5'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                  wand: {
                                                                                                                                                                                                                                                                                                                                                    // this jamie wandell's link
                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/aEU0381vybgMf3q17s',
                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                    alveena: {
                                                                                                                                                                                                                                                                                                                                                      // this alveena rehman's link
                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/00g8zEgqsbgMg7u9DZ',
                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                      vivero: {
                                                                                                                                                                                                                                                                                                                                                        // this emmanuel vivero's link
                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/7sI4jo6PS4So6wU9E0',
                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                        dylan: {
                                                                                                                                                                                                                                                                                                                                                          // this dylan squillacioti's link
                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o8zE3DG1Gc4oM03r',
                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                          smartai: {
                                                                                                                                                                                                                                                                                                                                                            // this dakarai nash's link
                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/eVa3fk7TWckQbReaI6',
                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                            huntertutors: {
                                                                                                                                                                                                                                                                                                                                                              // this hunter leija's link
                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/dR6g26eikbgM08w3fF',
                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PTEvgAEwgW5HLpR'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                              drew: {
                                                                                                                                                                                                                                                                                                                                                                // this drew collin's link
                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/6oE2bg2zC1Gc1cA5nP',
                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PSl7nLqMuMcq0mA'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                jacob: {
                                                                                                                                                                                                                                                                                                                                                                  // this jacob young's link
                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/7sIdTY0ru5Ws9J67vW',
                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PPndoEk4nUw99IH'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                  // olivia: {
                                                                                                                                                                                                                                                                                                                                                                  //   // this olivia wolfe's link
                                                                                                                                                                                                                                                                                                                                                                  //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                  //     unlimited: 'https://buy.stripe.com/6oE9DIb68doU2gE2bE',
                                                                                                                                                                                                                                                                                                                                                                  //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                  //     stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                  //   },
                                                                                                                                                                                                                                                                                                                                                                    ariana: {
                                                                                                                                                                                                                                                                                                                                                                      // this ariana taylor's link
                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/bIY038a24gB64oM2bF',
                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                      rylee: {
                                                                                                                                                                                                                                                                                                                                                                        // this rylee curtis's link
                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/5kA4jo5LO1Gc9J68A4',
                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                        kamal: {
                                                                                                                                                                                                                                                                                                                                                                          // this kamal shah's link
                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/3cs6rw2zC84Af3q2bH',
                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PUsZ5LotS2bCg8U'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                          bobby: {
                                                                                                                                                                                                                                                                                                                                                                            // this bobby habetler's link
                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/6oE5ns7TW98EcVi7w2',
                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PV1EMCugtkesbpC'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                            esther: {
                                                                                                                                                                                                                                                                                                                                                                              // this esther chen's link
                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/3cscPUa2498E3kIg2z',
                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PTVj6FTv3NaJ8Ir'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                              rhombus: {
                                                                                                                                                                                                                                                                                                                                                                                // this philip tsoukalas 's link
                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/bIY3fk3DG84A3kI8A8',
                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                han: {
                                                                                                                                                                                                                                                                                                                                                                                  // this hannah polson 's link
                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/fZe6rw1vy0C82gEeYx',
                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PVHeZDluylc9Y59'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                  hiral: {
                                                                                                                                                                                                                                                                                                                                                                                    // this hiral shah 's link
                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/9AQ9DI8Y0gB67AY9Ee',
                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PVNobIXYisgj9J6'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                    smile: {
                                                                                                                                                                                                                                                                                                                                                                                      // this zaire smith 's link
                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM2bg8Y084AdZm5nZ',
                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PVm0VCPICCOtOLm'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                      yanisa: {
                                                                                                                                                                                                                                                                                                                                                                                        // this Yanisa kee 's link
                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/3cscPUa24gB62gE9Eg',
                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PVvkDQpyu4XCDIz'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                        delcie: {
                                                                                                                                                                                                                                                                                                                                                                                          // this delcie lewallen 's link
                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/3csdTYdeg2Kg5sQ03H',
                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                           // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                          yi: {
                                                                                                                                                                                                                                                                                                                                                                                            // this yi fu 's link
                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/00gg26cac5Ws7AYg2H',
                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                           //   stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                            andy: {
                                                                                                                                                                                                                                                                                                                                                                                              // this ahn andy vu  's link
                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/3cseY20rufx26wU8Ag',
                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PX1QtDQSK3SRqLS'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                              nato: {
                                                                                                                                                                                                                                                                                                                                                                                                // this renato  vieira's link
                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/eVa4jo2zC0C87AY7wd',
                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                //  stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                regan: {
                                                                                                                                                                                                                                                                                                                                                                                                  // this regan  thomas's link
                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/aEUcPU4HKgB6g7uaIq',
                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PXQgqEAeuFN2ckj'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                  marame: {
                                                                                                                                                                                                                                                                                                                                                                                                    // this marame hughe's link
                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/dR617ceikdoU1cA9En',
                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PW9tgFKvaSFIHCz'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                    sam: {
                                                                                                                                                                                                                                                                                                                                                                                                      // this samuel intagliata's link
                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/9AQ8zEcac1Gc5sQ5o8',
                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                       // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                      maria: {
                                                                                                                                                                                                                                                                                                                                                                                                        // this maria pawelka's link
                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/4gwdTY6PS0C8dZmeYJ',
                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                       //   stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                        shafin: {
                                                                                                                                                                                                                                                                                                                                                                                                          // this shafin salam's link
                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o8zEa245Ws1cA17U',
                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                           // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                          tben: {
                                                                                                                                                                                                                                                                                                                                                                                                            // this tyler berkowitz's link
                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/bIYcPU0ruckQ4oM6sf',
                                                                                                                                                                                                                                                                                                                                                                                                           //   beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PYBRMEUxlkjkkXC'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                            z: {
                                                                                                                                                                                                                                                                                                                                                                                                              // this Nika Zoufan's link
                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/fZe7vAfmo2Kg8F2cQE',
                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PYGv0ABfvsi5oH8'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                              jwang: {
                                                                                                                                                                                                                                                                                                                                                                                                                // this justin wang's link
                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/00gaHMfmo5WsdZm7wl',
                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PZFBuL68DseleIQ'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                aieducation: {
                                                                                                                                                                                                                                                                                                                                                                                                                  // this shahab nassar's link
                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/cN26rw8Y0doUg7u03U',
                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                  //  stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                  yang: {
                                                                                                                                                                                                                                                                                                                                                                                                                    // this taehyuk yang's link
                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/9AQ8zE8Y084AaNa3g7',
                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PQGATFMf7Ua7fDY'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                    learnfromai: {
                                                                                                                                                                                                                                                                                                                                                                                                                      // this dylan nijankin's link
                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/bIY038gqs84AcVi7wo',
                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PZbJIG11iTc83U1'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                      pande: {
                                                                                                                                                                                                                                                                                                                                                                                                                        // this arnav pande's link
                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/9AQeY2gqsacIbRe7wp',
                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                        //  stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                        josephine: {
                                                                                                                                                                                                                                                                                                                                                                                                                          // this josephine kinlan's link
                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/dR6dTY0rugB6g7ucQK',
                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PZh6WA94E6lUkvg'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                          solar: {
                                                                                                                                                                                                                                                                                                                                                                                                                            // this soyunbike ahmetjan's link
                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/5kA5nsgqsdoU5sQ8Av',
                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PZxqvL6jT1DVEcM'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                            natalia: {
                                                                                                                                                                                                                                                                                                                                                                                                                              // this natalia monnet's link
                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/eVag263DG4So4oM040',
                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                               // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                              school: {
                                                                                                                                                                                                                                                                                                                                                                                                                                // this karthik pasupuleti's link
                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/aEU6rwgqsfx28F2cQN',
                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PZjldEO921UOWYo'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                jess: {
                                                                                                                                                                                                                                                                                                                                                                                                                                  // this jessyka lucia's link
                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/9AQ9DI1vy1GcaNaeYW',
                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                 //   stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                  genius: {
                                                                                                                                                                                                                                                                                                                                                                                                                                    // this jaalin harris gathings link
                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/14keY27TW3Ok2gEg31',
                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PaNWPLH7aVO6Xoa'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                    daniel: {
                                                                                                                                                                                                                                                                                                                                                                                                                                      // this daniel pereira  link
                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wMbLQ6PS4SoaNaeYY',
                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PaQ7BInc97v7ee9'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                      megan: {
                                                                                                                                                                                                                                                                                                                                                                                                                                        // this megan konrad  link
                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/00g2bggqs98E1cA6st',
                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PaSMhFrpp7RQ6Vz'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                        sb: {
                                                                                                                                                                                                                                                                                                                                                                                                                                          // this sarah roodin link
                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/fZe4job681GcbRe8AC',
                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PaV2EHrQULEdxZz'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                          fluffy: {
                                                                                                                                                                                                                                                                                                                                                                                                                                            // this nyema Peters link
                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/3cs3fk5LOckQ8F2g35',
                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                             // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                            eman: {
                                                                                                                                                                                                                                                                                                                                                                                                                                              // this emmanuel darkwa's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/cN2g264HKdoU7AY4ko',
                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1Pb1fJAnwrtjcVEd'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                              suz: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                // this suzette segovia's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/6oE7vA0ruesY4oM3gl',
                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PYbBpGT38OSDFB6'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                supersz: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this sophia zhang's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/fZedTY7TW1Gcf3q04a',
                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PbRmlDMeL8jB7cr'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                  maxwing: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this samson nguyen's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wMbLQeik2Kg5sQ3gn',
                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PbW4vHIqNm4U7Qr'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                    tutorpro: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this KAssandra Campos's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/14kcPUa2484AbRe18g',
                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1Pcq17LBzAPtRU7b'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                      adam: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this adam chang's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o7vAb68acI2gEdV3',
                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PS2sxJsEEG7UV0q'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                        asim: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this asim waheed's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/5kA4jogqs0C8dZm7wG',
                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PcwcPIU86t6nBOu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                          star: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this adam hashmi's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/eVaeY2a242Kgf3qcR2',
                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PbHl2HVXNEi2FQt'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                            grow: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this Mahlik Carroll's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/00gdTYeik3Okg7u4kv',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PdE0VGNw38AwbBe'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                              haylee: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this haylee nguyen's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                 // stripeAccountId: 'acct_1PQ9S4CEqRWsCvEu'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                fatima: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this fatima ijaz's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/6oE3fkeikfx2dZmaIW',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1Pe0hsHM2qCnMrnD'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  meg: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this megan schaeefer's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/dR6g265LOckQ8F2dV9',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //  stripeAccountId: 'acct_1Pen72EMFTLC6cOk'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    greta: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this greta morgan's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/dR6g265LOckQ8F2dV9',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1Pf4uGIGfx2dD5xk'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      vunk: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this via tay's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/dR6g265LOckQ8F2dV9',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PczmzAWI6rsM9kT'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        samar: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this samar singh's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/dR6g265LOckQ8F2dV9',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PfR7oEGGUPwDhTZ'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          hassan: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this alhassan ahmed's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/dR6g265LOckQ8F2dV9',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PfSo1AIAg3pwZVz'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            bbb: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this bryan bravo's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PfWWtL4b9Qr2qnj'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              nev: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this nevaan jhanjhariya's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PbRlHLxJ5ITGg0V'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                jw: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this Jwalant Jitendra Wadhwa's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PfkDoC3cJcXKSjI'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  awesome: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this ameerah mapp's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PfWx2EBOhC3h4SR'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // superman: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //   // this diego platt's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //     unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //     stripeAccountId: 'acct_1PfoCAFVHywFNuzG'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    //   },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      susy: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this susana morelos's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PfpIuB0og9jbhFL'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        try: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this jia yun li's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PfpH9Qwv2EX3Lzh'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          learning: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this gabriela aguirre's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PfkXyHIcSiRmn6k'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            aunnie: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this aunnie ochieng's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PgEniRogZsG672u'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              peach: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this mikah fulmer's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PgBIbRtDw9BsYnR'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                powers: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this jessica chin's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PgW9uKC8SfWFu7E'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  taryn: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this taryn reich's 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PgqzLKKECzS5wJT'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    bianca: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this bianca reber's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PguOICmVADmUWgq'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      trysuper: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this seong shin's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PguJXCTnmUkCwgg'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        superkylee: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this kyleen deley's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PgqwTJtlnUFxAe8'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          explore: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this Keira Lally's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PgcW4AzkHaumDZO'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            superaf: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this alan fehn's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PhFAoH3UjiGhKlU'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              charlie: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this charles todaro's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PhY33ISxO4IVmoi'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                kcu: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this jackson U's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PhbJ0JHUJ0g5TO8'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  breeze: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this breeze Pickford's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PhcAJKVWeSeQdyg'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    julia: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this julia cavossa's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PhdxxJzauylpPdq'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      cupcake: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this zianab afolabi's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PhflVLjg3xNMaco'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        caroline: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this caroline chan's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1Phfk5BKSmGRT8iD'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          cool: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this olutobi adeniji's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1Pi0rVGOWWIGbEkI'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            quiz: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this kyle heidinger's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PhFcBEVsSPawqaA'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              brenlee: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this brendalee collentro's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PiOcNI4C0JLTN8V'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                nima: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this nima elie's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PifgVJAIt2IEUbF'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  shannon: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this Shannon Francis's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1Pj0vuCf4tcW5hFT'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    myhero: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this lian eskridge's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1Pj7tmF6YcTQ2d8C'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      superehero: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this alyssa smith's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PjOK1Fs0zJ5QMZ0'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        olivia: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this olivia hansen's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PeMNGAmSno0fRX8'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          art: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this noah leroy's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PkAkOKwerQqT9eR'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            care: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this jessica babin's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PjRzUB2StFH8Ld6'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ahssto: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this andrew hines's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PlCisLVccl6NiLD'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                blake: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this blake budmon's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PlE3kGbcXczHVkn'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  learn: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this april tellez's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PlDHoJvqBKX02lk'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    milton: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this milton correa's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1Plc3ZRuQtSoOiD3'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      supermat: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this mataneal kenea's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1Plw2hBv1hh9hNtg'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        faltas: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this mina falta's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1Plud3ApkMUVEpTx'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          inspire: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this rene burkert's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PlynlLh2rBkdZba'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            start: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this Gray Davidson's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1P9XWQEoi5GZlPmm'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              king: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this elizabeth king's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1Pmye6F62jMtvflL'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                soldier: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this jonathan tshamala's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1Pn2BIHdEgCnsl3S'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  yahya: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this yahya al qauodhi's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PnNdfBLh9671F0c'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    bast: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this VINCENT HERRERA's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PnRF5FzTfI9gtYD'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      helper: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this akshaya sreeram's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1Pn9ILHgJpXRgURb'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ren: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this ren kato's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PnpMXP6jBiHOwlL'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          priya: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this aarushi priya's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PnoJh2LeCGuRXbb'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            kamar: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this kamar castillo's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1Pks0qBmBO0YBYlm'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              nate: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this nate rgoers 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PpFo3Kh9FIyUtSM'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                nishu: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this Nishu KC 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/28o3fkfmo5Wsg7ubMZ',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PmgKCAIt8juuteT'                                                                                                                                                                             
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  study: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this is colin higginson's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PpY5EDQXEWQqiTx'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    emma: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is Emma Gilvary's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PpYrsF3FRnmtWSv'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      dante: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is dante alcocer's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PpYXnE96NNz3F9c'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ai: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is hameed alghaithi's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PoXA6AuoKyoHszK'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          superwoman: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is jasmine waite's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1Ppba1ETqX77gsLK'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            joe: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this is jospeh sherman's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PpbI3I22d8gvyW2'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              sheba: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is dalal harajli's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PpvIZDL9Rfc0gPX'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                space: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this is kenneth hughey's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PpxpjCUBrPxpKlN'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  john: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this is john bricker's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PqFOsKnjL4AYrte'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    supermia: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is mia antonacci's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PqFPVCnv0wvdFMN'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      smartstudy: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is eli morrissey's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PqIRGHL3A5Op5aK'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        om: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is om patel's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PpAB6FH7mpBqzNf'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          boston: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is joseph badger's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1Pqcr0DMVc7bv5wf'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ronnie: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this is cassidy farrar's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PqfTMKFmq9XSrBG'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ayesha: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is ayesha imran's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PqhG8LInWqRbcEE'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                zainab: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this is zianab 's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1PhflVLjg3xNMaco'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  big: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this is samuel peck 's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1Pr0uXKtHBy0UEaR'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    asha: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is asha kumari's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PTNsn1wB02EMq9b'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      urstuddybuddy: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is lai ching weng wendy's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PrjX3LlgOGpPw1P'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        manel: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is manel jaballah's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PrquBEZhjpznY2u'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          thrive: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is michael tran's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1Ps77FDTLPVw0BLW'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            mia: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this is mia tovar's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PqN3UKZtaQs4wtr'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              fiona: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is matthew guerrero's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PsC1FLom8k2Fjvy'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                rosa: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this is lizvette de la rosa's  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1Ps6JqHYfYBiZvuW'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ev: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this is evan iftekhars  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1PsW34GvIWCpRWx0'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    earn: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is marlowe clarke  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PtA8sFdSz4dFlLX'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      snorlax: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is elijah steele  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PtaHWKZMOAVVhp6'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        joy: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is alicia humayun  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1PvNvYAj4TSSOPny'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ja: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is jason taveras  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PvlI1Rp4yRMsd0C'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // super: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            //   // this is Carey Wickerman  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            //     unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            //     stripeAccountId: 'acct_1Pw3tKRw4oMMBR0x'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            //   },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              intan: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is naufal intan   link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1PviCWHn5z8dTDKV'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                wizard: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this is wikas  van der schyff    link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1Px9sDR18ncKkfYZ'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // wizard: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //   // this is wikas  van der schyff    link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //     unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //     stripeAccountId: 'acct_1Px9sDR18ncKkfYZ'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  //   },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    shine: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is amanda yang  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PxBPDRporfKB1eQ'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      sand: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is sandhya melpakkam  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1PxCRE09TsXBYxGJ'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ollie: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is oliver reich  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1Pv2DA09HTtUp4kS'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          assia: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is assia rekhaderich  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PyGeCFA4MXeoyMF'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            hornets: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this is marko kovacevich  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1PyHDoGgPpJAzJPc'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              superman: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is shiv motwani  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1Pz01AByNiMxzjXv'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                superstudent: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this is Makayla Ojentis link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1Pye1tGCTPu4iLb6'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  laura: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this is laura giraldo link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1Q02pYGKFAAh8vx4'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    jello: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is anjella estrella link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1PygFIDMDxTlHMGP'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      happy: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is nguyen huyen  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1Q0QSyR8vUSWLYZE'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        clever: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is danny orlov  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1Q0VdvFvbxlu9i4e'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          boost: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is joshua garrett  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1Q0rhi098bOv9aHc'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            bose: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this is chad anderson  link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1Q18IELuB8x4m4Sl'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              sunny: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is Nathan Casamassima's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1Q2ijQ2K5L8Ng72o'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                funked: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  // this is sean schaefer's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    stripeAccountId: 'acct_1Q34YAKgG1cTN6hU'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  jds: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    // this is muhamma d jalaldar j dar  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      stripeAccountId: 'acct_1Q2yveQqztjmZbkk'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    kjhala: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      // this is krisha jhala 's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        stripeAccountId: 'acct_1Q3gTwJtnLp5CjEv'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      yippee: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is condoleeza zeng  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1Q4q0IKchHFAjFCa'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          tutor: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        // this is jada lester  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          stripeAccountId: 'acct_1Q6EtGCvBn26z9hq'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        zehra: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is zehra ali  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1Q61q4Dl7QmSvr6G'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           kris: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          // this is kristoffer ortega   's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            stripeAccountId: 'acct_1Q6HapKGg0VtwdP5'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          amber: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            // this is Amber Heinemann  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              stripeAccountId: 'acct_1PxbgmERgJhWHM6Z'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            mike: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              // this is Mike Beck  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                stripeAccountId: 'acct_1Q8SZACTLPJbZGS8'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              super: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is yasaswi madala  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1Q8TkmGxhltLAnj6'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  nino: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // this is nino castellano  's link
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  unlimited: 'https://buy.stripe.com/8wM4jodeg5Ws6wU01S',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  stripeAccountId: 'acct_1Q9EOGE6tw95T7vQ'                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                },
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                                                                                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                                                                                                                    
                                                                                                                                                                                                                                                                                                                                                  
                                                                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                              
                                                                                                                                                                                                                                                                                                                                            
                                                                                                                                                                                                                                                                                                                                          
                                                                                                                                                                                                                                                                                                                                        
                                                                                                                                    
                                                                                                                                  
                                                                                                                                
                                                                                                                              
                                                                                                                            
                                                                                                                          
                                                                                                                                                                  
                                                                                                                        
                                                                                                                      
                                                                                                                    
                                                                                                                  
                                                                                                                
                                                                                                              
                                                                                                              // susanna: {
                                                                                                              //   // this is susanna lee's link
                                                                                                              //     pro: 'https://buy.stripe.com/cN2g26gqs84A1cA00J',
                                                                                                              //     unlimited: 'https://buy.stripe.com/bIYbLQa243Ok3kIaGb',
                                                                                                              //     beyondunlimited: 'https://buy.stripe.com/3cs8zE4HKbgMf3q3dn', 
                                                                                                              //   },
                  
                  
                
              
            
          
        
      
                                          

                              


  };

   // Initialize state to hold the current Stripe links
   //const [currentLinks, setCurrentLinks] = useState(defaultLinks);
   const checkMark = <span style={{color: '#22c55e', fontSize: '24px'}}>✓</span>;
   const xMark = <span style={{color: '#ef4444', fontSize: '24px'}}>✗</span>;
  
  
  //  const handleInviteCodeChange = (e) => {
  //   const code = e.target.value.toLowerCase();
  //   setInviteCode(code);

  //   if (inviteLinks[code]) {
  //    // setCurrentLinks(inviteLinks[code]);
  //     setCodeValidity('Valid code ✅ - you unlocked a free trial click below to claim it.');
  //   } else {
  //    //setCurrentLinks(defaultLinks);
  //     if (code.trim() !== '') {
  //       setCodeValidity('Invalid code ❌');
  //     } else {
  //       setCodeValidity('');
  //     }
  //   }
  // };
  const handleInviteCodeChange = (e) => {
    const code = e.target.value.toLowerCase();
    setInviteCode(code);
  
    if (code === 'free') {
      setCodeValidity('free');
    } else if (inviteLinks[code]) {
      setCodeValidity('Valid code ✅ - you unlocked a free trial click below to claim it.');
    } else {
      if (code.trim() !== '') {
        setCodeValidity('Invalid code ❌');
      } else {
        setCodeValidity('');
      }
    }
  };

  // const handleFreeUpgrade = async () => {
  //   const userId = auth.currentUser?.uid;
  //   if (!userId) {
  //     alert('No user signed in.');
  //     return;
  //   }
  
  //   try {
  //     await updateDoc(doc(firestore, 'users', userId), {
  //       subscriptionPlan: 'beyond_pro'
  //     });
  //     alert('Your account has been upgraded to Beyond Pro!');
  //     onClose(); // Close the popup after successful upgrade
  //   } catch (error) {
  //     console.error('Error upgrading account:', error);
  //     alert('An error occurred while upgrading your account. Please try again.');
  //   }
  // };
  const handleFreeUpgrade = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      alert('No user signed in.');
      return;
    }
  
    try {
      // Update user's subscription plan
      await updateDoc(doc(firestore, 'users', userId), {
        subscriptionPlan: 'beyond_pro'
      });
  
      // Get user's name
      const userDoc = await getDoc(doc(firestore, 'users', userId));
      const userName = userDoc.data().name;
  
      // Add entry to free_upgrades collection
      await addDoc(collection(firestore, 'free_upgrades'), {
        userId: userId,
        name: userName,
        upgradeDate: serverTimestamp()
      });
  
      alert('Your account has been upgraded to Beyond Pro!');
      onClose(); // Close the popup after successful upgrade
    } catch (error) {
      console.error('Error upgrading account:', error);
      alert('An error occurred while upgrading your account. Please try again.');
    }
  };

  // useEffect(() => {
  //   // Fetching and setting video URLs
  //   // fetchVideoUrl('demo3.MOV').then(setDemo3VideoUrl);
  //    fetchMemesUrls().then(setMemesUrls);

  //   // Other code...
  // }, []);
  


  if (!show) {
    return null;
  }



  return (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}
  >
     <div
      style={{
        // background: radial-gradient(circle at center, #e6f7ff, #e6f7ff 30%, #e6f7ff 60%, #e6f7ff 90%, #e6f7ff);  background-size: cover;
        background: 'radial-gradient(circle at center, #e6f7ff, #e6f7ff 30%, #e6f7ff 60%, #e6f7ff 90%, #e6f7ff)',
        backgroundSize: 'cover',
        
       // background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 1500%)',
        // background: '#f0fff0',
      //  background: 'radial-gradient(circle, white, #39FF14)', // Add this line
        borderRadius: '10px',
        padding: '20px',
        width: '100%', // Set width to 100%
        height: '100%', // Set height to 100%
        textAlign: 'center',
        overflow: 'auto', // Add this line
      }}
    >

{/* 
<h1 style={{ fontFamily: 'Poppins, sans-serif' }}>
  <span style={{ color: 'black' }}>Activate</span> your superpowers!
</h1> */}
{/* <h4 style={{ margin: 0 }}>
  Get unlimited access to GPT4o, DALL-E image generator, meme <br /> generator, goal boosters & multiplayer games:
</h4> */}
       <h2 style={{ 
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: '700',
      textAlign: 'center', 
      fontSize: '1.4em',
      maxWidth: '450px',
      margin: '0 auto',
      marginBottom: '20px',
      marginTop: '5px'
    }}>
      <span style={{ fontSize: '1.6em', fontStyle: 'italic'  }}>Faster </span> than ChatGPT, <br /> 
      <span style={{ fontSize: '1.6em', fontStyle: 'italic'  }}>Smarter</span> than your teacher, <br /> 
      <span style={{ fontSize: '1.6em', fontStyle: 'italic'  }}>More creative</span> than Kahoot...
      <br />It’s a bird...It’s a plane...It’s...
    </h2>


          <h1 style={{
            fontFamily: "'Poppins', sans-serif",
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '48px',
            lineHeight: '30px',
            color: 'black',
            textAlign: 'center'
          }}>
            {/* <span style={{color: '#045AC6'}}>Supertutor</span> */}

            <img src={logo} alt="Description" width="190" style={{marginTop: '-50px', transform: 'rotate(10deg)'}} />

            <br />
            {/* <span style={{fontSize: '24px'}}>Activate your superpowers</span> */}
          </h1>
          <img src="/supertutorlogo.png" alt="Supertutor" style={{ height: '190px', marginTop: '-50px', marginRight: '10px' }} />

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {/* <div className="feature-item">
    <span className="feature-emoji"></span>
    <h4 style={{ margin: 0 }}>Get unlimited access to GPT4o, DALL-E image generator, meme generator, goal boosters & multiplayer games</h4>
  </div> */}
  {/* <div className="feature-item">
    <span className="feature-emoji">🎉</span>
    <h4 style={{ margin: 0 }}>Make learning fun</h4>
  </div>
 */}

  {/* <div className="feature-item">
    <span className="feature-emoji">💡</span>
    <h4 style={{ margin: 0 }}>Learn challenging concepts easily with relatable metaphors</h4>
  </div> */}
  {/* <div className="feature-item">
    <span className="feature-emoji">🧠</span>
    <h4 style={{ margin: 0 }}>Supercharge your mind with multiplayer games</h4>
  </div> */}

  {/* <div className="feature-item">
    <span className="feature-emoji">😂</span>
    <h4 style={{ margin: 0 }}>"Make meme" button to create custom educational memes which can increase memory retention by 83%</h4>
  </div> */}

</div>

      {/* <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Accelerate your learning capabilities </h4>
      <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Get your work done fast & fun </h4>
      <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Drag & drop in any images & PDFs to SUPERTUTOR </h4>
      <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Auto-generate customizable flashcards </h4>

      <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Learn challenging concepts easily with relatable metaphors </h4> */}

      {/* <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Make your research easier with academic expert and video "get sources" buttons </h4> */}
  {/* <p>✅ Ace all your tests with instant memorable answers</p>
  <p>✅ Get unlimited supertutor answer generations using the best GPT4 models specialized to help you learn better (& save money compared to $20 per month chatgpt4)</p>
  <p>✅ Ace your homework</p>
  <p>✅ Summarize and explain any web articles</p> 
  <p>✅ Make you research easier with unlimited helpful outside source reference generations</p>
  <p>✅ Make memorizing easier with scientifically validated supertutor techniques</p>  */}  

<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: '20px' }}>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <input 
    type="text" 
    placeholder="Enter promo code" 
    value={inviteCode} 
    onChange={handleInviteCodeChange} 
    style={{ padding: '10px', fontSize: '1em', borderRadius: '5px', border: '1px solid #ccc', width: '250px', textAlign: 'center' }} 
  />
</div>         
 {/* <div style={{ color: codeValidity.includes('Invalid') ? 'red' : 'green', marginTop: '10px' }}>{codeValidity}</div> */}
 <div style={{ height: '24px', marginTop: '10px' }}>
  {codeValidity !== 'free' && (
    <div style={{ color: codeValidity.includes('Invalid') ? 'red' : 'green' }}>
      {codeValidity}
    </div>
  )}
</div>
{/* <a href={currentLinks.unlimited} rel="noopener noreferrer">
            <button className="startButton" style={{ background: 'radial-gradient(circle, white, #39FF14)', color: 'black', border: '3px solid blue', borderRadius: '6px', padding: '10px 20px', fontSize: '1em', cursor: 'pointer', transition: 'transform 0.3s', fontWeight: 'bold', marginBottom: '10px' }}>
              GET UNLIMITED PLAN ✅<br />
              <span style={{ fontSize: '0.4em' }}>$9.99/month for unlimited Supertutor credits</span>
            </button>
          </a> */}



  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>



  {codeValidity === 'free' ? (
  <button
    className="startButton"
    style={{
      background: 'rgb(0, 240, 255)',
      color: 'black',
      border: '2px solid black',
      borderRadius: '6px',
      padding: '15px 30px',
      fontSize: '1.2em',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      fontWeight: 'bold',
      marginBottom: '10px',
      boxShadow: '3px 3px 0 black',
      width: '300px',
    }}
    onClick={handleFreeUpgrade}
  >
    ACCEPT FREE UPGRADE{ checkMark}
  </button>
) : (
  <button
  className="startButton"
  style={{
    // background: '#98FB98',
    background: 'rgb(0, 240, 255)', // Background color using RGB values

    color: 'black',
    border: '2px solid black',
    borderRadius: '6px',
    padding: '15px 30px',
    fontSize: '1.2em',
    cursor: 'pointer',
    transition: 'all 0.2s ease', // Changed transition
    fontWeight: 'bold',
    marginBottom: '10px',
    boxShadow: '3px 3px 0 black', // Changed boxShadow
    width: '300px',
  }}
   onClick={() => handleUpgrade('price_1OtEfVJcTMg4yEil4MbigM91')}
  //onClick={() => handleUpgrade('unlimited')}

>
  {codeValidity.includes('Valid code') ? (
    <>
  {"ACCEPT FREE TRIAL "}{checkMark}<br />
      <span style={{ fontSize: '0.4em' }}>free trial then just $9.99/month for unlimited credits</span>
    </>
  ) : (
    <>
  {"GET UNLIMITED PLAN"}{ checkMark}<br />
      <span style={{ fontSize: '0.4em' }}>$9.99/month for unlimited Supertutor credits</span>
    </>
  )}
</button>
)}



</div>
{/* <a href={currentLinks.beyondunlimited} rel="noopener noreferrer">
  <button
    className="startButton"
    style={{
      padding: '10px 20px',
      borderRadius: '5px',
      backgroundColor: 'white',
      border: '1px solid black',
      cursor: 'pointer',
    }}
  >
    {codeValidity.includes('Valid code') ? (
      <>
        ACCEPT FREE TRIAL ✅<br />
        <span style={{ fontSize: '0.4em' }}>
         free trial then just $16.99/month for unlimited credits and premium access to our partner apps
        </span>
      </>
    ) : (
      <>
        GET BEYOND UNLIMITED PLAN ✅<br />
        <span style={{ fontSize: '0.6em' }}>
          $16.99/month for unlimited credits and premium access to our partner apps
        </span>
      </>
    )}
  </button>
</a> */}
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

<button
  className="startButton"
  style={{
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: 'white',
    border: '1px solid black',
    cursor: 'pointer',
  }}
  // onClick={() => handleUpgrade('price_1P344YJcTMg4yEilyHYQh0Bl')}
  onClick={() => handleUpgrade('price_1PcHyOJcTMg4yEilQk6WsUCn')}

 // onClick={() => handleUpgrade('beyondunlimited')}

>
  {codeValidity.includes('Valid code') ? (
    <>
  {"ACCEPT FREE TRIAL"}{ checkMark}<br />
      <span style={{ fontSize: '0.4em' }}>
        free trial then just $99/year for unlimited credits and 2 months free
      </span>
    </>
  ) : (
    <>
  {"GET UNLIMITED PLAN YEARLY"}{ checkMark}<br />
      <span style={{ fontSize: '0.6em' }}>
        $99/year for unlimited credits and 2 months free 
      </span>
    </>
  )}
</button>
</div>


{/* 
          <a href={currentLinks.beyondunlimited} rel="noopener noreferrer">
  <button className="startButton" style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: 'white', border: '1px solid black', cursor: 'pointer' }}>
    GET BEYOND UNLIMITED PLAN ✅<br />
    <span style={{ fontSize: '0.6em' }}>$16.99/month for unlimited credits and premium access to our partner apps</span>
  </button>
</a> */}

{/* 
<a href="https://buy.stripe.com/bIY8zE7TW5Ws4oM5kl" rel="noopener noreferrer">
  <button
    className="startButton"
    style={{
      background: 'radial-gradient(circle, white, #39FF14)',
      color: 'black',
      border: '3px solid blue',
      borderRadius: '6px',
      padding: '10px 20px',
      fontSize: '1em',
      cursor: 'pointer',
      transition: 'transform 0.3s',
      fontWeight: 'bold',
      marginBottom: '10px',
    }}
  >
    ACCEPT UNLIMITED PRO FREE TRIAL <span role="img" aria-label="check mark">✅</span><br />
    <span style={{ fontSize: '0.4em' }}>3 days free then just $9.99/month for unlimited credits</span>
  </button>
</a> */}


{/* <a href="https://buy.stripe.com/00gdTYgqsbgM6wUeUU" rel="noopener noreferrer">
  <button
    className="startButton"
    style={{
      padding: '10px 20px',
      borderRadius: '5px',
      backgroundColor: 'white',
      border: '1px solid black',
      cursor: 'pointer',
    }}
  >
    ACCEPT REGULAR PRO FREE TRIAL <span role="img" aria-label="check mark">✅</span><br />
    <span style={{ fontSize: '0.6em' }}>3 days free then just $2.99/month for 30 credits daily</span>
  </button>
</a> */}

</div>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<p style={{ fontSize: '0.7em' }}> Experience all the wonderful Supertutor feautures | Satisfaction gaurentee | Cancel anytime </p>

<img src="/people.png" alt="Supertutor Logo" style={{ width: '350px', marginTop: '10px' }} />   

  <p style={{ fontSize: '0.7em' }}> Loved by students and professionals worldwide  </p>
{/*   */}
  
{/* 
  <div style={{ marginTop: '40px', fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto' }}>
  <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>How Supertutor compares:</h2>
  <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', position: 'relative' }}>
  <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 10px' }}>
      <thead>
        <tr>
          <th style={{ padding: '12px', textAlign: 'center', borderBottom: '2px solid #f0f0f0', color: '#333', fontWeight: 'bold' }}>ChatGPT Plus</th>
          <th style={{ padding: '12px', textAlign: 'center', borderBottom: '2px solid #f0f0f0', color: '#333', fontWeight: 'bold' }}>SUPERTUTOR</th>
          <th style={{ padding: '12px', textAlign: 'center', borderBottom: '2px solid #f0f0f0', color: '#333', fontWeight: 'bold' }}>Chegg</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>$20 per month</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>$9.99 per month</td>
          <td style={{ padding: '12px', color: '#666' }}>$19.95 per month</td>
        </tr>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>Robotic answers</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Engaging personalized answers</td>
          <td style={{ padding: '12px', color: '#666' }}>Pre-written answers</td>
        </tr>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>No memes</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Memory-boosting memes</td>
          <td style={{ padding: '12px', color: '#666' }}>Limited visual content</td>
        </tr>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>Limited GPT-4o access</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Unlimited GPT-4o access</td>
          <td style={{ padding: '12px', color: '#666' }}>Limited GPT access</td>
        </tr>
      
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>Dall-E image generation</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Dall-E image generation</td>
          <td style={{ padding: '12px', color: '#666' }}>No image generation</td>
        </tr>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>No goal tracking</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Goal tracking and boosting</td>
          <td style={{ padding: '12px', color: '#666' }}>No goal tracking</td>
        </tr>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>Upload PDF and images</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Upload PDF and images</td>
          <td style={{ padding: '12px', color: '#666' }}>No images</td>
        </tr>
        <tr>
          <td style={{ padding: '12px', color: '#666' }}>No multiplayer games</td>
          <td style={{ padding: '12px', color: '#666', fontWeight: 'bold' }}>Awesome Multiplayer games</td>
          <td style={{ padding: '12px', color: '#666' }}>No multiplayer games</td>
        </tr>
      </tbody>
    </table>
    <div style={{
      position: 'absolute',
      top: '10px',
      left: '32.33%',
      width: '37.33%',
      height: 'calc(100% - 20px)',
      border: '3px solid #007bff',
      borderRadius: '10px',
      pointerEvents: 'none',
    }}></div>
  </div>

</div>
<style jsx>{`
  .comparison-container {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  .comparison-table {
    width: 100%;
    border-collapse: collapse;
  }
  .comparison-table th,
  .comparison-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
  }
  .comparison-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .comparison-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  @media (max-width: 600px) {
    .comparison-table th,
    .comparison-table td {
      padding: 8px;
      font-size: 14px;
    }
  }
`}</style> */}

{/* 
<div style={{fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto', padding: '20px'}}>
      <h2 style={{textAlign: 'center', marginBottom: '20px', color: '#333'}}>How Supertutor Compares</h2>
      <div style={{backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px'}}>
        <table style={{width: '100%', borderCollapse: 'separate', borderSpacing: '0 10px'}}>
          <thead>
            <tr>
              <th style={{padding: '12px', textAlign: 'center', borderBottom: '2px solid #f0f0f0', color: '#333', fontWeight: 'bold', width: '25%'}}>Features</th>
              <th style={{
  padding: '12px',
  textAlign: 'center',
  borderBottom: '2px solid #f0f0f0',
  color: 'rgb(65, 105, 225)', // Royal blue
  fontWeight: 'bold',
  border: '2px solid rgb(65, 105, 225)', // Royal blue
  borderRadius: '20px'
}}>
  SUPERTUTOR
</th>
              <th style={{padding: '12px', textAlign: 'center', borderBottom: '2px solid #f0f0f0', color: '#333', fontWeight: 'bold'}}>ChatGPT Plus</th>
              <th style={{padding: '12px', textAlign: 'center', borderBottom: '2px solid #f0f0f0', color: '#333', fontWeight: 'bold'}}>Chegg</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Price</td>
              <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$9.99/month</td>
              <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$20/month</td>
              <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$19.95/month</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Engaging personalized answers</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Memory-boosting memes</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Unlimited GPT-4o access</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Dall-E image generation</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Goal tracking and boosting</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Upload PDF and images</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
            </tr>
            <tr>
              <td style={{padding: '12px', color: '#666'}}>Multiplayer trivia & imgination games</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
              <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> */}
    <div style={{
  fontFamily: 'Arial, sans-serif',
  maxWidth: '100%',
  margin: '0 auto',
  padding: '20px',
  boxSizing: 'border-box'
}}>
  <style>
    {`
      @media (max-width: 768px) {
        table, th, td {
          font-size: 14px;
        }
        th, td {
          padding: 8px 4px;
        }
      }
    `}
  </style>
  
  <h2 style={{
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
    fontSize: 'clamp(1.5rem, 4vw, 2rem)'
  }}>How Supertutor Compares</h2>

  <div style={{
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    overflowX: 'auto'
  }}>
    <table style={{
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
      fontSize: 'clamp(0.8rem, 2.5vw, 1rem)'
    }}>
      <thead>
        <tr>
          <th style={{
            padding: '12px',
            textAlign: 'left',
            borderBottom: '2px solid #f0f0f0',
            color: '#333',
            fontWeight: 'bold',
            width: '25%'
          }}>Features</th>
          <th style={{
            padding: '12px',
            textAlign: 'center',
            borderBottom: '2px solid #f0f0f0',
            color: 'rgb(65, 105, 225)',
            fontWeight: 'bold',
            border: '2px solid rgb(65, 105, 225)',
            borderRadius: '20px'
          }}>
            SUPERTUTOR
          </th>
          <th style={{
            padding: '12px',
            textAlign: 'center',
            borderBottom: '2px solid #f0f0f0',
            color: '#333',
            fontWeight: 'bold'
          }}>ChatGPT Plus</th>
          <th style={{
            padding: '12px',
            textAlign: 'center',
            borderBottom: '2px solid #f0f0f0',
            color: '#333',
            fontWeight: 'bold'
          }}>Chegg</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Price</td>
          <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$9.99/month</td>
          <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$20/month</td>
          <td style={{padding: '12px', color: '#666', textAlign: 'center'}}>$19.95/month</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Engaging personalized answers</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Generate and sings original songs</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Memory-boosting memes</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Unlimited GPT-4o access</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Dall-E image generation</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Goal tracking and boosting</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Upload PDF and images</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
        <tr>
          <td style={{padding: '12px', color: '#666'}}>Multiplayer trivia & imagination games</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{checkMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
          <td style={{padding: '12px', textAlign: 'center'}}>{xMark}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


{/* <Slider {...settings}>
  {memesUrls.map((url, index) => (
    <div key={index}>
      <img
        src={url}
        alt="Meme"
        style={{ width: '120px', height: '120px', margin: '0 auto', objectFit: 'contain' }}
      />
    </div>
  ))}
</Slider> */}


  {/* {demo3VideoUrl && (
          <video
            src={demo3VideoUrl}
            controls
            style={{
              maxWidth: '40%', // Adjust this value as needed
              maxHeight: '500px', // Adjust this value to change the height of the video

              borderRadius: '10px', // Optional for styling
              margin: '20px 0', // Adds space above and below the video player
            }}
          >
            Your browser does not support the video tag.
          </video>
        )} */}

        
<div className="image-container">
 



  {/* Review from Stanford Student
  


  <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
 
  <div style={{ display: 'flex', alignItems: 'center' }}>
  <img 
  src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2Falex.png?alt=media&token=58856c78-9b06-4544-adbe-c47badb64dda" 
  alt="Alex" 
  style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
/>
    <div>
      <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
      <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
        "Supertutor has been extremely helpful for me with all my school work. If I am unsure how to do any type of problem, I can easily take a picture of the question and it will display a step by step explanation on how to solve it."
      </p>
      <p style={{ fontSize: '0.9em', color: '#666' }}>- Alex K, Syracuse student</p>
    </div>
  </div>
</div>



<div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
 
 <div style={{ display: 'flex', alignItems: 'center' }}>
 <img 
 src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2FScreenshot%202024-04-03%20at%2011.18.09%E2%80%AFPM.png?alt=media&token=f5bb52da-c2ee-4ff2-86ab-d0af7c184789" 
 alt="Alex" 
 style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
/>
   <div>
     <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
     <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
       "Supertutor's metaphors literally made me LOL while learning. You just have to try it! 🤩🤣."
     </p>
     <p style={{ fontSize: '0.9em', color: '#666' }}>- Sandria, USC student</p>
   </div>
 </div>
</div>




    <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F1668220697746.jpg?alt=media&token=cf001e2c-2937-46ce-8da1-2f09fba6a7ba" 
        alt="Stanford Student" 
        style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
      />
      <div>
        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
        <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
          "Supertutor is better than ChatGPT4 for my learning style and it's half the price! I love SUPERTUTOR!" - Samantha, Stanford Student
        </p>
      </div>
    </div>
  </div>
  
  */}


    {/* Review from USC Student
    
        <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F106578407-screenshot2020-06-15at2.20.03pm.jpg?alt=media&token=4655c0c5-e4a4-4bbc-bcb3-574cfc1f09f9" 
        alt="MIT Student" 
        style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
      />
      <div>
        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
        <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
          "Supertutor makes learning tough technical topics easy:)" - Layla, MIT Student
        </p>
      </div>
    </div>
  </div>
*/}

 

  {/* Review from USC Student
  
    <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2Fistockphoto-1278978684-612x612.jpg?alt=media&token=4e3542f8-deef-4a85-92ec-2ee49eafd42b" 
        alt="USC Student" 
        style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
      />
      <div>
        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
        <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
          "Memes make me smarter! Supertutor is the only learning site that gives me custom educational memes... LOVE that feature!" - John, USC Student
        </p>
      </div>
    </div>
  </div>*/}


  {/* Review from Oxford Student
  
    <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2F1665953285776.jpg?alt=media&token=79f7c70d-9215-4f71-a2a8-6c9571722c8b" 
        alt="Oxford Student" 
        style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
      />
      <div>
        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
        <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
          "Supertutor helps me finish my assignments 3x faster while making it fun!" - Abigail, Oxford Student
        </p>
      </div>
    </div>
  </div>
*/}

  <div>
  {/* Review from NYU Law Student
    <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '500px', margin: '20px auto', textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/aisupertutor.appspot.com/o/faces%2Fjulia.jpg?alt=media&token=c4af4d37-763b-41de-94d7-50aa2d197461" 
        alt="NYU Law Student" 
        style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', marginRight: '10px' }}
      />
      <div>
        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>⭐⭐⭐⭐⭐</div>
        <p style={{ fontStyle: 'italic', margin: '10px 0' }}>
          "Supertutor is a powerful resource that brings my grades from average to super!!" - Ray, NYU Law Student
        </p>
      </div>
    </div>
  </div> */}



</div>


 
          </div>




          {/* <img src="/supertutorlogo.png" alt="Supertutor Logo" style={{ width: '90px', marginTop: '1px' }} />  */}

  {/* <h4>SUPERTUTOR helps you to learn easily, perform better on tests, & SUPERcharge your memory skills</h4> */}

 {/* {memesUrls.map((url, index) => (
    <img
      key={index}
      src={url}
      alt={`Meme ${index}`}
      style={{ 
        width: 'auto', 
        height: '160px', 
        margin: '10px', 
        objectFit: 'contain',
        borderRadius: '8px' // Add this line
      }}
    />
  ))} */}
  
  </div>

<div style={{ marginTop: '20px', textAlign: 'center' }}>

   {/* <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> Supercharge your memory skills with mnemonics </h4>
   <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> "Play guiz game" button gives you customized quizes for your topics </h4>
   <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> "Make meme" button to create a custom educational memes which can increase memeory retention by 83%</h4>
    <h4 style={{ textAlign: 'center' }}> <span role="img" aria-label="check mark">✅</span> "Get sources" button for links to expert content on your subject </h4>
 */}

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  
{/*   
  <div className="feature-item">
    <span className="feature-emoji">📸</span>
    <h4 style={{ margin: 0 }}>Drag & drop in any images & PDFs to SUPERTUTOR</h4>
  </div>
  
  <div className="feature-item">
    <span className="feature-emoji">🎯</span>
    <h4 style={{ margin: 0 }}>"Play quiz game" button gives you customized quizzes for your topics</h4>
  </div>

   */}
{/* <div className="feature-item">
    <span className="feature-emoji">🃏</span>
    <h4 style={{ margin: 0 }}>Auto-generate customizable flashcards</h4>
  </div> */}


  </div>
  {/* <a href={currentLinks.unlimited} rel="noopener noreferrer">
  <button className="startButton" style={{
      background: '#98FB98', // Use a pale, saturated green color
      color: 'black',
    border: '2px solid black',
    borderRadius: '6px',
    padding: '10px 20px',
    fontSize: '1em',
    cursor: 'pointer',
    transition: 'transform 0.3s',
    fontWeight: 'bold',
    marginBottom: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add this line
  }}>
    GET UNLIMITED PLAN ✅<br />
    <span style={{ fontSize: '0.4em' }}>$9.99/month for unlimited credits</span>
  </button>
</a> */}

{/* 
<button
  className="startButton"
  style={{
    background: '#98FB98',
    color: 'black',
    border: '2px solid black',
    borderRadius: '6px',
    padding: '15px 30px',
    fontSize: '1.2em',
    cursor: 'pointer',
    transition: 'all 0.2s ease', // Changed transition
    fontWeight: 'bold',
    marginBottom: '10px',
    boxShadow: '3px 3px 0 black', // Changed boxShadow
    width: '300px',
  }}
  // onClick={() => handleUpgrade('unlimited')}

  onClick={() => handleUpgrade('price_1OtEfVJcTMg4yEil4MbigM91')}
>
  {codeValidity.includes('Valid code') ? (
    <>
      ACCEPT FREE TRIAL ✅<br />
      <span style={{ fontSize: '0.4em' }}>free trial then just $9.99/month for unlimited credits</span>
    </>
  ) : (
    <>
      GET UNLIMITED PLAN ✅<br />
      <span style={{ fontSize: '0.4em' }}>$9.99/month for unlimited Supertutor credits</span>
    </>
  )}
</button>

<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <button
    className="startButton"
    style={{
      padding: '10px 20px',
      borderRadius: '5px',
      backgroundColor: 'white',
      border: '1px solid black',
      cursor: 'pointer',
    }}
    //onClick={() => handleUpgrade('beyondunlimited')}

    onClick={() => handleUpgrade('price_1PcHyOJcTMg4yEilQk6WsUCn')}
  >
    {codeValidity.includes('Valid code') ? (
      <>
        ACCEPT FREE TRIAL ✅<br />
        <span style={{ fontSize: '0.4em' }}>
          free trial then just $99/month for unlimited credits and 2 months free
        </span>
      </>
    ) : (
      <>
        GET UNLIMITED PLAN YEARLY ✅<br />
        <span style={{ fontSize: '0.6em' }}>
          $99/year for unlimited credits and 2 months free
        </span>
      </>
    )}
  </button>
</div> */}

{/* <a href={currentLinks.unlimited} rel="noopener noreferrer">
  <button
    className="startButton"
    style={{
      background: '#98FB98', // Use a pale, saturated green color
      color: 'black',
      border: '2px solid black',
      borderRadius: '6px',
      padding: '15px 30px', // Increase the size
      fontSize: '1.2em', // Increase the size
      cursor: 'pointer',
      transition: 'transform 0.3s',
      fontWeight: 'bold',
      marginBottom: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Add a shadow
    }}
  >
    {codeValidity.includes('Valid code') ? (
      <>
        ACCEPT FREE TRIAL ✅<br />
        <span style={{ fontSize: '0.4em' }}>free trial then just $9.99/month for unlimited credits</span>
      </>
    ) : (
      <>
        GET UNLIMITED PLAN ✅<br />
        <span style={{ fontSize: '0.4em' }}>$9.99/month for unlimited Supertutor credits</span>
      </>
    )}
  </button>

  </a>  */}

{/* <span onClick={() => handleUpgrade('price_1OtAtyJcTMg4yEiliPyYtJgf')}>o</span> */}


          {/* <a href={currentLinks.pro} rel="noopener noreferrer">
            <button className="startButton" style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: 'white', border: '1px solid black', cursor: 'pointer' }}>
            ACCEPT FREE TRIAL PRO PLAN ✅<br />
              <span style={{ fontSize: '0.6em' }}>Then $2.99/month for 30 daily credits </span>
            </button>
          </a> */}
{/* 
          <h2 style={{ color: '#333', textAlign: 'center' }}>Why Choose SuperTutor?</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li><strong>Enhanced Accuracy and Intelligence:</strong> SuperTutor's GPT-4 offers up to 40% improvement in accuracy compared to ChatGPT3.5.</li>
        <li><strong>Tailored Educational Support:</strong> Specifically fine-tuned for educational purposes, making learning more efficient.</li>
        <li><strong>Why pay for GPT4 quality vs GPT3.5:</strong> GPT4 passes a simulated bar exam with a score around the top 10% of test takers; in contrast, GPT-3.5’s score was around the bottom 10%.</li>
        <li><strong>Cost-Effective Learning Solution:</strong> Just $9.99/month for unlimited access, providing superior value at half the cost of ChatGPT4.</li>
        <li><strong>Engaging and Memorable Learning Experience:</strong> Utilizes relatable metaphors and mnemonics to enhance memory skills.</li>
        <li><strong>Satisfaction or Money-Back Guarantee:</strong> Commitment to quality with a money-back guarantee.</li>
      </ul> */}




{/* <a href="https://buy.stripe.com/bIY8zE7TW5Ws4oM5kl" rel="noopener noreferrer">
  <button
    className="startButton"
    style={{
      background: 'radial-gradient(circle, white, #39FF14)',
      color: 'black',
      border: '3px solid blue',
      borderRadius: '6px',
      padding: '10px 20px',
      fontSize: '1em',
      cursor: 'pointer',
      transition: 'transform 0.3s',
      fontWeight: 'bold',
      marginBottom: '10px',
    }}
  >
    ACCEPT UNLIMITED PRO FREE TRIAL <span role="img" aria-label="check mark">✅</span><br />
    <span style={{ fontSize: '0.4em' }}>3 days free then just $9.99/month for unlimited credits</span>
  </button>
</a> */}


{/* 
  <p style={{ fontSize: '0.7em' }}> GPT-4 demonstrates a remarkable improvement, achieving a 40% reduction in factual errors compared to GPT-3, offering more reliable and accurate information for critical decision-making. </p> */}

  {/* <p style={{ fontSize: '0.7em' }}> Why pay for access to Supertutor's GPT4 +? "GPT4 passes a simulated bar exam with a score around the top 10% of test takers; in contrast, GPT-3.5’s score was around the bottom 10%" </p> */}

       {/* <button 
    onClick={onClose}
    style={{
      marginTop: '10px',
      padding: '5px 10px',
      borderRadius: '5px',
      background: 'rgb(255, 239, 213)',
      border: 'none',
      cursor: 'pointer',
    }}
  >
    close
  </button> */}
  {(subscriptionPlan === 'pro' || subscriptionPlan === 'beyond_pro') && (
  <button 
    onClick={onClose}
    style={{
      marginTop: '10px',
      padding: '5px 10px',
      borderRadius: '5px',
      background: 'rgb(255, 239, 213)',
      border: 'none',
      cursor: 'pointer',
    }}
  >
    close
  </button>
)}



<button
  onClick={() => {
    // Log out the user
    signOut(auth);
  }}
  style={{
    padding: '5px 10px',
    borderRadius: '5px',
    background: 'white',
    border: '0px solid black',
    cursor: 'pointer',
    fontSize: '1em',
    position: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: '9999',
  }}
>
  &#8592;
</button>
</div>


    </div>
  </div>
);
}

export default UpgradePopup;













