import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from './firebase';
import { useNavigate } from 'react-router-dom';
import './MySuperAnswers.css';

function MySuperAnswers() {
  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);
  const [songs, setSongs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [renameTitle, setRenameTitle] = useState('');
  const [activeTab, setActiveTab] = useState('conversations'); // 'conversations' or 'songs'

  useEffect(() => {
    const fetchConversations = async () => {
      if (auth.currentUser) {
        const q = query(collection(firestore, 'users', auth.currentUser.uid, 'conversations'));
        const querySnapshot = await getDocs(q);
        const fetchedConversations = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const sortedConversations = fetchedConversations.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        setConversations(sortedConversations);
      }
    };

    const fetchSongs = async () => {
      if (auth.currentUser) {
        const q = query(collection(firestore, 'users', auth.currentUser.uid, 'generatedSongs'));
        const querySnapshot = await getDocs(q);
        const fetchedSongs = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const sortedSongs = fetchedSongs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        setSongs(sortedSongs);
      }
    };

    fetchConversations();
    fetchSongs();
  }, []);

  const handleConversationClick = (conversationId) => {
    navigate(`/${conversationId}`);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionsClick = (id) => {
    setShowOptions(id);
    setRenameTitle('');
  };

  const handleDeleteClick = async (id) => {
    try {
      if (activeTab === 'conversations') {
        await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', id));
        setConversations(conversations.filter((conversation) => conversation.id !== id));
      } else {
        await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'generatedSongs', id));
        setSongs(songs.filter((song) => song.id !== id));
      }
      setShowOptions(null);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleRenameClick = (id) => {
    const item = activeTab === 'conversations' 
      ? conversations.find((conversation) => conversation.id === id)
      : songs.find((song) => song.id === id);
    setRenameTitle(item.title);
  };

  const handleRenameSubmit = async (e, id) => {
    e.preventDefault();
    try {
      const newTitle = renameTitle.trim();
      if (activeTab === 'conversations') {
        await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', id), {
          title: newTitle,
        });
        setConversations(
          conversations.map((conversation) =>
            conversation.id === id ? { ...conversation, title: newTitle } : conversation
          )
        );
      } else {
        await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'generatedSongs', id), {
          title: newTitle,
        });
        setSongs(
          songs.map((song) =>
            song.id === id ? { ...song, title: newTitle } : song
          )
        );
      }
      setShowOptions(null);
    } catch (error) {
      console.error('Error renaming item:', error);
    }
  };

  const filteredItems = activeTab === 'conversations'
    ? conversations.filter((conversation) =>
        conversation.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : songs.filter((song) =>
        song.title.toLowerCase().includes(searchTerm.toLowerCase())
      );



   return (
    <div className="my-super-answers">
      <h2>My History</h2>
      <div className="tab-buttons">
        <button
          onClick={() => setActiveTab('conversations')}
          className={`tab-button ${activeTab === 'conversations' ? 'active' : ''}`}
        >
          Conversations
        </button>
        <button
          onClick={() => setActiveTab('songs')}
          className={`tab-button ${activeTab === 'songs' ? 'active' : ''}`}
        >
          Song History
        </button>
      </div>
      <input
        type="text"
        placeholder={`Search ${activeTab}...`}
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <div className="items-list">
        {filteredItems.map((item) => (
          <div key={item.id} className="item">
            <div className="item-header">
              <span
                onClick={() => handleOptionsClick(item.id)}
                className="options-button"
              >
                ⋮
              </span>
              <span className="item-date">
                {item.createdAt.toDate().toLocaleString()}
              </span>
            </div>
            {showOptions === item.id && (
              <div className="item-options">
                <button onClick={() => handleDeleteClick(item.id)}>Delete</button>
                <button onClick={() => handleRenameClick(item.id)}>Rename</button>
                {renameTitle && (
                  <form onSubmit={(e) => handleRenameSubmit(e, item.id)} className="rename-form">
                    <input
                      type="text"
                      value={renameTitle}
                      onChange={(e) => setRenameTitle(e.target.value)}
                    />
                    <button type="submit">Save</button>
                  </form>
                )}
              </div>
            )}
            {activeTab === 'conversations' ? (
              <button
                onClick={() => handleConversationClick(item.id)}
                className="item-button"
              >
                {item.title}
              </button>
            ) : (
              <div className="song-item">
                <span className="song-title">{item.title}</span>
                <audio controls src={item.audio_url} className="audio-player" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MySuperAnswers;
// import React, { useState, useEffect } from 'react';
// import { collection, query, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
// import { auth, firestore } from './firebase';
// import { useNavigate } from 'react-router-dom';

// function MySuperAnswers() {
//   const navigate = useNavigate();
//   const [conversations, setConversations] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showOptions, setShowOptions] = useState(null);
//   const [renameTitle, setRenameTitle] = useState('');

//   const createTitle = (content, maxSentences = 2) => {
//     if (!content) return 'Untitled';
//     const sentences = content.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0);
//     const titleSentences = sentences.slice(0, maxSentences);
//     let title = titleSentences.join('. ');
//     if (sentences.length > maxSentences) {
//       title += '...';
//     }
//     if (!title.endsWith('.') && !title.endsWith('!') && !title.endsWith('?')) {
//       title += '.';
//     }
//     return title;
//   };

//   useEffect(() => {
//     const fetchConversations = async () => {
//       if (auth.currentUser) {
//         const q = query(collection(firestore, 'users', auth.currentUser.uid, 'conversations'));
//         const querySnapshot = await getDocs(q);
//         const fetchedConversations = await Promise.all(querySnapshot.docs.map(async (doc) => {
//           const data = doc.data();
//           const firstMessage = data.messages && data.messages.length > 0 ? data.messages[0].content : '';
//           let title = data.title;
//           if (!title || title === firstMessage) {
//             title = createTitle(firstMessage);
//             // Update the title in Firestore
//             await updateDoc(doc.ref, { title });
//           }
//           return {
//             id: doc.id,
//             ...data,
//             title
//           };
//         }));
//         const sortedConversations = fetchedConversations.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
//         setConversations(sortedConversations);
//       }
//     };
//     fetchConversations();
//   }, []);

//   const handleConversationClick = (conversationId) => {
//     navigate(`/${conversationId}`);
//   };

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleOptionsClick = (conversationId) => {
//     setShowOptions(conversationId);
//     setRenameTitle('');
//   };

//   const handleDeleteClick = async (conversationId) => {
//     try {
//       await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', conversationId));
//       setConversations(conversations.filter((conversation) => conversation.id !== conversationId));
//       setShowOptions(null);
//     } catch (error) {
//       console.error('Error deleting conversation:', error);
//     }
//   };

//   const handleRenameClick = (conversationId) => {
//     const conversation = conversations.find((conversation) => conversation.id === conversationId);
//     setRenameTitle(conversation.title);
//   };

//   const handleRenameSubmit = async (e, conversationId) => {
//     e.preventDefault();
//     try {
//       const newTitle = createTitle(renameTitle);
//       await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', conversationId), {
//         title: newTitle,
//       });
//       setConversations(
//         conversations.map((conversation) =>
//           conversation.id === conversationId ? { ...conversation, title: newTitle } : conversation
//         )
//       );
//       setShowOptions(null);
//     } catch (error) {
//       console.error('Error renaming conversation:', error);
//     }
//   };

//   const filteredConversations = conversations.filter((conversation) =>
//     conversation.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div>
//       <h2>My History</h2>
//       <input
//         type="text"
//         placeholder="Search conversations..."
//         value={searchTerm}
//         onChange={handleSearch}
//         style={{
//           width: '69%',
//           padding: '10px',
//           fontSize: '1em',
//           border: '1px solid black',
//           borderRadius: '5px',
//           marginBottom: '20px',
//         }}
//       />
//       {filteredConversations.map((conversation) => (
//         <div 
//           key={conversation.id} 
//           style={{ 
//             marginBottom: '20px', 
//             display: 'flex', 
//             alignItems: 'center', 
//             justifyContent: 'center'
//           }}
//         >
//           <span
//             onClick={() => handleOptionsClick(conversation.id)}
//             style={{ marginRight: '10px', cursor: 'pointer' }}
//           >
//             ⋮
//           </span>
//           {showOptions === conversation.id && (
//             <div style={{ marginRight: '10px' }}>
//               <button onClick={() => handleDeleteClick(conversation.id)}>Delete</button>
//               <button onClick={() => handleRenameClick(conversation.id)}>Rename</button>
//               {renameTitle && (
//                 <form onSubmit={(e) => handleRenameSubmit(e, conversation.id)}>
//                   <input
//                     type="text"
//                     value={renameTitle}
//                     onChange={(e) => setRenameTitle(e.target.value)}
//                   />
//                   <button type="submit">Save</button>
//                 </form>
//               )}
//             </div>
//           )}
//           <button
//             onClick={() => handleConversationClick(conversation.id)}
//             style={{
//               fontSize: '1em',
//               padding: '10px 20px',
//               background: 'white',
//               color: 'black',
//               border: '1px solid black',
//               borderRadius: '15px',
//               cursor: 'pointer',
//             }}
//           >
//             {conversation.title}
//           </button>
//           <span style={{ marginLeft: '10px', fontSize: '0.8em', color: 'gray' }}>
//             {conversation.createdAt.toDate().toLocaleString()}
//           </span>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default MySuperAnswers;
